import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useEffect, useState } from "react";
import { API_PATCH, DBRequest, useConfig } from "../../api/api";
import Loader from "../../components/special/Loader";
import SuccessCheck from "../../components/special/SuccessCheck";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslation } from "react-i18next";
const Sign = ({ token, link }) => {
  const { t } = useTranslation();
  const config = useConfig();

  // popups0
  const [openAccept, setOpenAccept] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [clientData, setClientData] = useState({});
  const [location, setLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reject, setReject] = useState(false);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation({ latitude: latitude, longitude: longitude });
          // Use latitude and longitude data here
        },
        (error) => {
          // Handle error when location access is denied or disabled
          switch (error.code) {
            case error.PERMISSION_DENIED:
              const result = window.confirm(t("The Location is Required!"));
              if (result) {
                getLocation();
              } else {
                window.location.reload();
              }
              break;
            case error.POSITION_UNAVAILABLE:
              // Location information is unavailable
              window.alert(t("Location information is unavailable."));
              window.location.reload();
              break;
            case error.TIMEOUT:
              // The request to get user location timed out
              window.alert(t("Timeout while trying to get user location."));
              window.location.reload();
              break;
            case error.UNKNOWN_ERROR:
              // An unknown error occurred
              window.alert(
                t("An unknown error occurred while getting user location.")
              );
              window.location.reload();
              break;
          }
        }
      );
    } else {
      // Geolocation is not supported by the browser
    }
  }
  function getDeviceInfo() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const screenOrientation = window.screen.orientation
      ? window.screen.orientation.type
      : "";
    return {
      deviceInfo: {
        screenWidth: screenWidth,
        screenHeight: screenHeight,
        screenOrientation: screenOrientation,
      },
    };
  }
  function getBrowserInfo() {
    const browserName = navigator.appName;
    const browserVersion = navigator.appVersion;
    const platform = navigator.platform;
    return {
      browserInfo: {
        browserName: browserName,
        browserVersion: browserVersion,
        platform: platform,
      },
    };
  }
  function getLanguageInfo() {
    const userLanguage = navigator.language;
    return { language: userLanguage };
  }
  function getTimezone() {
    const timezoneOffset = new Date().getTimezoneOffset();
    return { timezoneOffset: timezoneOffset };
  }

  // actions
  function handleAccept() {
    setIsLoading(true);
    const newClientData = {
      ...getBrowserInfo(),
      ...getDeviceInfo(),
      ...getLanguageInfo(),
      ...getTimezone(),
    };
    getLocation();
    setOpenAccept(false);

    setClientData({ ...clientData, ...newClientData });
  }

  useEffect(() => {
    if (
      location.latitude &&
      clientData.browserInfo &&
      clientData.language &&
      clientData.timezoneOffset
    )
      handleSign();
  }, [clientData, location]);

  function handleSign() {
    const data = {
      ...clientData,
      location: { ...location },
      status: "accepted",
    };
    DBRequest({
      config,
      path: "digital-signature/" + token,
      method: API_PATCH(data),
      onResponse: handleSuccess,
    });
  }

  function handleSuccess(data, success) {
    if (success) {
      setIsLoading(false);
      setSuccess(true);
    } else {
      window.alert("Ein Fehler ist aufgetreten");
      window.location.reload();
    }
  }

  function handleReject(checkboxes) {
    const data = {
      status: "rejected",
      reasons: checkboxes,
    };
    DBRequest({
      config,
      path: "digital-signature/" + token,
      method: API_PATCH(data),
      onResponse: handleRejectResponse,
    });
  }

  function handleRejectResponse(data, success) {
    if (success) {
      setIsLoading(false);
      setReject(true);
    } else {
      window.alert("Ein Fehler ist aufgetreten");
      window.location.reload();
    }
  }

  return (
    <Box className="flex flex-row justify-between">
      <Loader active={isLoading} color="rgb(40, 40, 40)" message={t("Sign")} />
      <Button
        variant="text"
        className="flex flex-row gap-2"
        color="error"
        onClick={() => setOpenDecline(true)}
      >
        <ThumbDownAltIcon />
        {t("Deny")}
      </Button>
      <Button
        variant="contained"
        className="flex flex-row gap-2"
        color="success"
        onClick={() => setOpenAccept(true)}
      >
        <FingerprintIcon />
        {t("Sign")}
      </Button>
      <AcceptPopUp
        open={openAccept}
        onClose={() => setOpenAccept(false)}
        onAccept={handleAccept}
      />
      <RejectPopUp
        open={openDecline}
        onClose={() => setOpenDecline(false)}
        onReject={handleReject}
      />
      <Success open={success} link={link} />
      <Reject open={reject} link={link} />
    </Box>
  );
};

const AcceptPopUp = ({ open, onClose, onAccept }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        className="flex flex-col items-center gap-4 p-4"
        sx={{ maxWidth: "100%", width: "400px" }}
      >
        <Typography variant="h2">{t("Accept Now")}</Typography>
        <WarningIcon sx={{ fontSize: "80px", color: "rgb(100, 100, 100)" }} />
        <Typography variant="p" sx={{ textAlign: "justify" }}>
          {t(
            "With the next confirmation you enter into a binding agreement. The process can still be canceled later, but there may be costs in the amount of the effort already invested."
          )}
        </Typography>
        <Box className="flex flex-row justify-between w-full">
          <Button onClick={onClose}>{t("Back")}</Button>
          <Button variant="contained" color="success" onClick={onAccept}>
            {t("Sign document with obligation to pay")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
const RejectPopUp = ({ open, onClose, onReject }) => {
  const { t } = useTranslation();

  const [checkboxes, setCheckboxes] = useState([
    {
      label: t("Price was too high"),
      value: false,
    },
    {
      label: t("Wrong time"),
      value: false,
    },
    {
      label: t("Circumstances have changed"),
      value: false,
    },
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        className="flex flex-col items-center gap-4 p-4"
        sx={{ maxWidth: "100%", width: "400px" }}
      >
        <Typography variant="h2">{t("Are you sure?")}</Typography>
        <WarningIcon sx={{ fontSize: "80px", color: "rgb(100, 100, 100)" }} />
        <Typography variant="p" sx={{ textAlign: "justify" }}>
          {t(
            "Are you really sure they really want to reject this document? Please let us know what was the reason for you to reject it."
          )}
        </Typography>
        <Box className="flex flex-col w-full">
          <Typography variant="h4">{t("Reasons")}</Typography>
          <FormGroup>
            {checkboxes.map((check, index) => (
              <FormControlLabel
                control={<Checkbox />}
                label={check.label}
                checked={check.value}
                onChange={() => {
                  var newCheckboxes = [...checkboxes];
                  newCheckboxes[index] = {
                    ...checkboxes[index],
                    value: !checkboxes[index].value,
                  };
                  setCheckboxes(newCheckboxes);
                }}
              />
            ))}
          </FormGroup>
        </Box>
        <Box className="flex flex-row justify-between w-full">
          <Button onClick={onClose}>{t("Back")}</Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => onReject(checkboxes)}
          >
            {t("Reject document")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const Success = ({ open, link }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <Box className="flex flex-col justify-center items-center">
        <Typography variant="h1">{t("Thank You")}</Typography>
        <Typography variant="h4">
          {t("Document successfully signed")}
        </Typography>
        <SuccessCheck />
        <a href={link}>
          <Button>{t("Back to Website")}</Button>
        </a>
      </Box>
    </Dialog>
  );
};

const Reject = ({ open, link }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <Box className="flex flex-col justify-center items-center">
        <Typography variant="h1">{t("Sorry to hear!")}</Typography>
        <a href={link}>
          <Button>{t("Back to Website")}</Button>
        </a>
      </Box>
    </Dialog>
  );
};

export default Sign;
