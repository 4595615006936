import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import {
  DBRequest,
  API_GET,
  API_PATCH,
  DBNoSetData,
  useConfig,
} from "../../api/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExoAccordion from "../exo/ExoAccordion";
import TemplatePreview from "../templates/TemplatePreview";
import TypeText from "../form/form-fields/TypeText";
import TypeColor from "../form/form-fields/TypeColor";

const TypographyEditor = ({ onFeedback = () => {} }) => {
  const [templates, setTemplates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  // db requests
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "templates",
      method: API_GET,
      onResponse: handleDataSet,
    });
  }, [config]);

  function handleDataSet(data) {
    setTemplates(data);
    setSelectedTemplate(data.data[0]);
    setIsLoading(false);
  }

  function handleTemplateSelect(event) {
    const newSelectedTemplate = templates.data.find(
      (obj) => obj.id === event.target.value
    );
    setSelectedTemplate({ ...newSelectedTemplate });
  }

  function handleHeightChange(page, headerHeight, footerHeight) {
    var newStyle = JSON.parse(selectedTemplate.styleJson);

    if (
      newStyle.page["headerP" + page + "Height"] === headerHeight &&
      newStyle.page["footerP" + page + "Height"] === footerHeight
    )
      return;
    newStyle.page["headerP" + page + "Height"] = headerHeight;
    newStyle.page["footerP" + page + "Height"] = footerHeight;

    setSelectedTemplate({
      ...selectedTemplate,
      styleJson: JSON.stringify(newStyle),
    });
  }

  const handleSave = () => {
    const templateId = selectedTemplate.id;
    const styleJson = { styleJson: selectedTemplate.styleJson };

    DBRequest({
      config,
      path: `templates/${templateId}`,
      method: API_PATCH(styleJson),
      onFeedback,
    });

    // update templates
    var newTemplates = [];

    templates.data.forEach((template) => {
      if (template.id === selectedTemplate.id) {
        newTemplates.push(selectedTemplate);
      } else {
        newTemplates.push(template);
      }
    });
    setTemplates({ ...templates, data: [...newTemplates] });
  };

  return (
    <Box className="grid grid-flow-col gap-4 flex-wrap overflow-auto w-full">
      {!isLoading && (
        <>
          <Box className="w-auto">
            <Box className="pb-4 flex flex-col gap-4 pt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  value={selectedTemplate.id}
                  label="Template"
                  onChange={handleTemplateSelect}
                >
                  {templates.data.map((element) => (
                    <MenuItem key={"template-" + element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                sx={{ width: "100%" }}
                onClick={handleSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
            <Properties
              template={selectedTemplate}
              onChange={setSelectedTemplate}
            />
          </Box>
          <TemplatePreview
            data={selectedTemplate}
            scaleable
            paginate
            scale={0.5}
            onChange={handleHeightChange}
          />
        </>
      )}
    </Box>
  );
};

const Properties = ({ onChange, template }) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (template.styleJson) {
      setStyles(JSON.parse(template.styleJson));
    }
  }, [template]);

  function handleStyleChange(newStyle) {
    const newStyles = { ...styles, ...newStyle };
    onChange({
      ...template,
      styleJson: JSON.stringify(newStyles),
    });
  }

  var options = Object.keys(styles).map((key, index) => ({
    ...styles[key],
    title: key,
    content: (
      <PropertyGroup
        key={"TypographyGroup-" + key + index}
        styles={styles}
        onChange={handleStyleChange}
        keyValue={key}
      />
    ),
  }));
  return <ExoAccordion elements={options} key={"typographyEditor"} />;
};

const PropertyGroup = ({ styles, onChange, keyValue }) => {
  const [dataCollection, setDataCollection] = useState(styles[keyValue]);

  useEffect(() => {
    setDataCollection(styles[keyValue]);
  }, [styles]);

  function handleUpdate(key, newValue) {
    setDataCollection({ ...dataCollection, [key]: newValue });
    onChange({ ...styles, [keyValue]: { ...dataCollection, [key]: newValue } });
  }

  return (
    <Box className="flex flex-col gap-4 py-4">
      {Object.keys(dataCollection).map((key, index) => {
        if (!EditOptions[key]) return;
        const EditComponent = EditOptions[key].component;
        return (
          <EditComponent
            key={"Tools-" + keyValue + "-" + index}
            field={EditOptions[key]}
            dataCollection={dataCollection}
            updateValidationOnChange={handleUpdate}
          />
        );
      })}
    </Box>
  );
};

const EditOptions = {
  fontFamily: {
    label: "Font Family",
    component: TypeText,
    key: "fontFamily",
    required: true,
  },
  fontSize: {
    label: "Font Size",
    component: TypeText,
    key: "fontSize",
    required: true,
  },
  fontWeight: {
    label: "Font Weight",
    component: TypeText,
    key: "fontWeight",
    required: true,
  },
  margin: {
    label: "Margin",
    component: TypeText,
    key: "margin",
    required: true,
  },
  padding: {
    label: "Padding",
    component: TypeText,
    key: "padding",
    required: true,
  },
  color: {
    label: "Text Color",
    component: TypeColor,
    key: "color",
    required: true,
  },
  url: {
    label: "Url",
    component: TypeText,
    key: "url",
    required: true,
  },
  size: {
    label: "Size",
    component: TypeText,
    key: "size",
    required: true,
  },
  lineHeight: {
    label: "Line Height",
    component: TypeText,
    key: "lineHeight",
    required: true,
  },
  top: {
    label: "Spacing Top",
    component: TypeText,
    key: "top",
    required: true,
  },
  right: {
    label: "Spacing Right",
    component: TypeText,
    key: "right",
    required: true,
  },
  bottom: {
    label: "Spacing Bottom",
    component: TypeText,
    key: "bottom",
    required: true,
  },
  left: {
    label: "Spacing Left",
    component: TypeText,
    key: "left",
    required: true,
  },
  backgroundColor: {
    label: "Background",
    component: TypeColor,
    key: "backgroundColor",
    required: true,
  },
};

export default TypographyEditor;
