import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import LabeledBox from "../../special/LabeledBox";
import { useEffect, useState } from "react";
import ExoDialog from "../../exo/ExoDialog";
import { useTranslation } from "react-i18next";
import ExoTabs from "../../exo/ExoTabs";
import ContactsIcon from "@mui/icons-material/Contacts";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SelectCompanies from "../../select/SelectCompanies";
import SelectContacts from "../../select/SelectContacts";
import SelectEmployees from "../../select/SelectEmployees";

const TypeMailSelect = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);

  function handleSelectionChange(value) {
    updateValidationOnChange([field.key], value);
    setSelection(value);
  }

  return (
    <>
      <LabeledBox
        className="col-span-2"
        onClick={() => setOpen(true)}
        label={field.label}
      >
        {selection.length === 0 && t("No CC Selected")}
        {selection.map((item, index) => (
          <MailItem item={item} />
        ))}
      </LabeledBox>
      <ExoDialog open={open}>
        <SelectMail
          label={field.label}
          onCancle={() => setOpen(false)}
          onSave={handleSelectionChange}
          startData={selection}
        />
      </ExoDialog>
    </>
  );
};

const MailItem = ({ item, onDelete = () => {} }) => {
  return (
    <Typography onClick={() => onDelete(item)}>
      <b>{item.name}</b> {`<${item.email}>`}
    </Typography>
  );
};

const SelectMail = ({ label, onCancle, onSave, startData }) => {
  const { t } = useTranslation();
  const [deliveryCCData, setDeliveryCCData] = useState([]);
  const [selection, setSelection] = useState({
    company: [],
    contact: [],
    employee: [],
  });
  const [preSelected, setPreSelected] = useState({
    company: [],
    contact: [],
    employee: [],
  });
  useEffect(() => {
    setPreSelected({
      company: startData.filter((obj) => obj.type === "company"),
      contact: startData.filter((obj) => obj.type === "contact"),
      employee: startData.filter((obj) => obj.type === "employee"),
    });
  }, [startData]);

  const tabs = [
    {
      label: t("Companies"),
      content: (
        <SelectCompanies
          onSelect={handleCompanysSelect}
          preSelected={preSelected.company}
        />
      ),
      icon: <ApartmentIcon />,
    },
    {
      label: t("Contacts"),
      content: (
        <SelectContacts
          onSelect={handleContactsSelect}
          preSelected={preSelected.contact}
        />
      ),
      icon: <ContactsIcon />,
    },
    {
      label: t("Employees"),
      content: (
        <SelectEmployees
          onSelect={handleEmployeesSelect}
          preSelected={preSelected.employee}
        />
      ),
      icon: <PeopleOutlinedIcon />,
    },
  ];

  /*  {
      label: t("Custom"),
      content: <Box>TEST2</Box>,
      icon: <AlternateEmailIcon />,
    },*/
  function handleCompanysSelect(data) {
    setSelection({ ...selection, company: data });
  }

  function handleContactsSelect(data) {
    setSelection({ ...selection, contact: data });
  }
  function handleEmployeesSelect(data) {
    setSelection({ ...selection, employee: data });
  }

  useEffect(() => {
    generateCCDeliveryData();
  }, [selection]);

  function generateCCDeliveryData() {
    var newDeliveryCCData = [];
    selection.company.forEach((element) => {
      newDeliveryCCData.push({
        email: element.mail,
        name: element.name,
        type: "company",
        id: element.id,
      });
    });
    selection.contact.forEach((element) => {
      newDeliveryCCData.push({
        email: element.mail,
        name: `${element.salutation} ${element.firstName} ${element.lastName}`,
        type: "contact",
        id: element.id,
      });
    });
    selection.employee.forEach((element) => {
      newDeliveryCCData.push({
        email: element.email,
        name: `${element.salutation} ${element.firstName} ${element.lastName}`,
        type: "employee",
        id: element.id,
      });
    });
    setDeliveryCCData(newDeliveryCCData);
  }

  function handleSave() {
    onSave(deliveryCCData);
    onCancle();
  }

  function handleDelete(item) {
    const newSelection = [...selection[item.type]];
    const index = newSelection.findIndex((obj) => obj.id === item.id);
    if (index >= 0) {
      newSelection.splice(index, 1);
    }
    setSelection({ ...selection, [item.type]: newSelection });
    setPreSelected({ ...selection, [item.type]: newSelection });
  }

  return (
    <Box className="flex flex-col h-full justify-between gap-2 overflow-hidden">
      <Box className="flex flex-col h-full gap-2 overflow-hidden pt-2">
        <LabeledBox label={label}>
          <Box className="overflow-y-auto mb-4" sx={{ maxHeight: "100px" }}>
            {deliveryCCData.length === 0 && t("No Mails Selected")}
            {deliveryCCData.map((item, index) => (
              <MailItem item={item} onDelete={handleDelete} />
            ))}
          </Box>
        </LabeledBox>
        <ExoTabs tabs={tabs} />
      </Box>
      <Box className="flex justify-between p-2">
        <Button variant="outlined" color="error" onClick={onCancle}>
          {t("Cancle")}
        </Button>
        <Button variant="contained" color="success" onClick={handleSave}>
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default TypeMailSelect;
