import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SettingsPopover } from "../SettingsPopover";
import { useTranslation } from "react-i18next";
import { API_DELETE, DBRequest, useConfig } from "../../../../api/api";
import ProjectWorkPackageStack from "../workPackage/ProjectWorkPackageStack";
import { tokens } from "../../../../global/theme/tokens";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const ProjectPhase = ({
  phase,
  onDelete,
  nr,
  onPhaseChange,
  onEdit,
  editmode,
  project,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const config = useConfig();

  const [isIn, setIsIn] = useState(true);

  const settings = [
    {
      label: t("Edit"),
      Icon: EditIcon,
      onClick: () => onEdit(phase),
    },
    {
      label: t("Delete"),
      Icon: DeleteIcon,
      onClick: handleDelete,
    },
  ];

  function handleDelete() {
    DBRequest({
      config,
      path: `project-phases/${phase.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(phase),
    });
  }

  return (
    <Box className=" backdrop-blur-lg rounded-lg">
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setIsIn(!isIn)}
        className="flex items-center w-full justify-between mb-4 px-4 py-2 rounded-lg"
        sx={{ justifyContent: "space-between", borderRadius: "0.5rem" }}
      >
        <Box className="flex items-center gap-3">
          {!editmode && (
            <KeyboardArrowDownIcon
              sx={{
                transform: `rotate(${isIn ? "180deg" : "0deg"})`,
                transition: "0.4s",
              }}
            />
          )}
          <Typography variant="h2">{phase.name}</Typography>
        </Box>
        {editmode && <SettingsPopover settings={settings} />}
      </Button>
      <Collapse in={isIn}>
        <ProjectWorkPackageStack
          project={project}
          phase={phase}
          onPhaseChange={onPhaseChange}
          editmode={editmode}
        />
      </Collapse>
    </Box>
  );
};
