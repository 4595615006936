import { Box, IconButton, Link, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

export const CardContentMail = ({ title, mail }) => {
  return (
    <Box className="flex flex-row gap-2 h-6 items-center">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h5" className=" monospace">
        {mail}
      </Typography>
      <Link href={`mailto:${mail}`}>
        <IconButton>
          <EmailIcon />
        </IconButton>
      </Link>
    </Box>
  );
};
