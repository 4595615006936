import { Box, Table, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../../api/api";
import CardGrid from "../../components/cards/layout/CardGrid";
import PictureNameCard from "../../components/cards/PictureNameCard";
import LocalizedStrings from "react-localization";
import CardContainer from "../../components/cards/layout/CardContainer";
import { CardContentText } from "../../components/cards/content/CardContentText";
import { CardContentMail } from "../../components/cards/content/CardContentMail";
import { CardContentLink } from "../../components/cards/content/CardContentLink";
import { CardContentPhone } from "../../components/cards/content/CardContentPhone";
import MoneyCard from "../../components/cards/MoneyCard";
import SkeletonCard from "../../components/cards/SkeletonCard";
import AddressCard from "../../components/cards/AddressCard";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useFetch from "../../api/useFetch";
import ExoDialog from "../../components/exo/ExoDialog";
import NewContact from "../../components/new/NewContact";

let L = new LocalizedStrings({
  en: {
    Nr: "Nr",
    InvoiceMail: "Invoice Mail",
    Phone: "Phone",
    Mail: "Mail",
    Url: "Website",
    Sales: "Sales",
    Edit: "Edit",
    Delete: "Delete",
  },
  de: {
    Nr: "Nr",
    InvoiceMail: "Rechnungs Mail",
    Phone: "Telefon",
    Mail: "Mail",
    Url: "Website",
    Sales: "Umsatz",
    Edit: "Bearbeiten",
    Delete: "Löschen",
  },
});
L.setLanguage("de");

const ExtendContact = ({ row, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`contacts/${row.id}`);

  // menu
  function handleDelete() {
    DBRequest({
      config,
      path: `contacts/${row.id}`,
      method: API_DELETE,
      onResponse: handleDeleteRow,
    });
  }

  const actions = [
    {
      icon: <ModeEditIcon />,
      name: L.Edit,
      onClick: handleEdit,
    },
    { icon: <DeleteOutlineIcon />, name: L.Delete, onClick: handleDelete },
  ];

  //edit contact
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }
  function handleDeleteRow() {
    onDelete(row);
  }

  return (
    <CardGrid>
      {!apiData ? (
        <ContactSkeleton />
      ) : (
        <>
          <SpeedDialMenu actions={actions} />
          <PictureNameCard
            span={1}
            name={`${apiData.data.salutation} ${apiData.data.firstName} ${apiData.data.lastName}`}
          />
          <CardContactData contact={apiData.data} />
          <MoneyCard title={L.Sales} amount={apiData.data.salesTotal} />
          <AddressCard address={apiData.data.address} />
        </>
      )}
      <ExoDialog open={open} limitWidth>
        <NewContact
          fullWidth
          onClose={() => setOpen(false)}
          onUpdate={handleUpdate}
          startData={startData}
        />
      </ExoDialog>
    </CardGrid>
  );
};

const CardContactData = ({ contact }) => {
  return (
    <CardContainer span={2}>
      <Table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6">{L.Nr}</Typography>
            </td>
            <td>
              <CardContentText text={contact.nr} />
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6">{L.Mail}</Typography>
            </td>
            <td>
              <CardContentMail mail={contact.mail} />
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6">{L.InvoiceMail}</Typography>
            </td>
            <td>
              <CardContentMail mail={contact.invoiceMail} />
            </td>
          </tr>
          {contact.phone && (
            <tr>
              <td>
                <Typography variant="h6">{L.Phone}</Typography>
              </td>
              <td>
                <CardContentPhone phone={contact.phone} />
              </td>
            </tr>
          )}
          {contact.url && (
            <tr>
              <td>
                <Typography variant="h6">{L.Url}</Typography>
              </td>
              <td>
                <CardContentLink href={contact.url} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </CardContainer>
  );
};

const ContactSkeleton = () => {
  return (
    <>
      <SkeletonCard height={186} />
      <SkeletonCard height={186} span={2} />
      <SkeletonCard height={186} />
      <SkeletonCard height={104} />
    </>
  );
};

export default ExtendContact;
