import Cookies from "universal-cookie";
import { SearchRequest, useConfig } from "../../api/api";
import { Box, Button, useTheme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchField } from "./search/SearchField";
import ExoDialog from "./ExoDialog";
import { SearchResponseGroup } from "./search/SearchResponseGroup";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../global/theme/tokens";
import { BsShiftFill } from "react-icons/bs";
import { TbSpace } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export const ExoSmartSearch = () => {
  const config = useConfig();
  const cookies = new Cookies();
  return (
    <Box>
      {config && (
        <SearchClient
          url={config.searchUrl}
          indexes={cookies.get("searchIndexes")}
        />
      )}
    </Box>
  );
};

const SearchClient = ({ url, indexes }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  function search(text) {
    if (!text) return setSearchResults(null);

    var queries = [];
    indexes.forEach((index) => {
      queries.push({
        indexUid: index,
        q: text,
      });
    });

    const searchData = { queries };

    SearchRequest({
      baseUrl: url,
      path: "multi-search",
      method: "post",
      data: searchData,
      onResponse: (res) => setSearchResults(res.results),
    });
  }

  useEffect(() => {
    // Event listener function for keydown event
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.code === "Space") {
        event.preventDefault(); // Prevent the default behavior of the key combination
        setOpen(!open);
      }
    };
    // Add event listener
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  return (
    <Box>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          bgcolor: colors.bgInput,
          ":hover": {
            bgcolor: colors.card,
          },
        }}
      >
        <SearchIcon />
        <Typography className="px-1">{t("Search")}</Typography>
        <Box className=" justify-center items-center gap-1 pl-4 hidden md:flex opacity-50">
          <BsShiftFill size={10} />+
          <TbSpace size={15} />
        </Box>
      </Button>
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <Box
          className=" max-h-full  w-11/12 flex flex-col mx-auto pt-10 gap-5 "
          sx={{ maxWidth: "750px" }}
        >
          <SearchField onChange={search} />
          {searchResults && (
            <Box className="w-full max-h-full overflow-y-auto flex flex-col gap-4">
              {searchResults.map((group) => (
                <SearchResponseGroup
                  key={group.indexUid}
                  group={group}
                  onClose={() => setOpen(false)}
                />
              ))}
            </Box>
          )}
        </Box>
      </ExoDialog>
    </Box>
  );
};
