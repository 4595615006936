import {
  useTheme,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import Feedback from "../../components/special/Feedback";
import { API_GET, API_PATCH, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import TextCard from "../../components/cards/TextCard";
import DisplayPositions from "../../components/preview/PreviewPositions";
import PictureNameCard from "../../components/cards/PictureNameCard";
import FileCard from "../../components/cards/FilesCard";
import DownloadIcon from "@mui/icons-material/Download";
import CardContainer from "../../components/cards/layout/CardContainer";
import { useTranslation } from "react-i18next";
import SkeletonCard from "../../components/cards/SkeletonCard";
import ExoResendMail from "../../components/exo/ExoResendMail";
import DateLabel from "../../components/label/DateLabel";
import CardGrid from "../../components/cards/layout/CardGrid";
import useFetch from "../../api/useFetch";

const ExtendOrder = ({ row, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`orders/${row.id}`);

  const [status, setStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);

  const columns = [
    {
      label: t("Position"),
      key: "position",
      align: "left",
      hiddenMobile: false,
      type: "number",
      width: "48px",
    },
    {
      label: t("Description"),
      key: "name",
      align: "left",
      hiddenMobile: false,
      type: "text",
      width: "auto",
    },
    {
      label: t("Quantity"),
      key: "amount",
      align: "center",
      hiddenMobile: false,
      type: "number",
      width: "80px",
    },
  ];

  useEffect(() => {
    if (apiData && apiData.data.id && apiData.data.status !== status) {
      const request = { status: status };
      onUpdate({ ...row, status: status });
      setApiData({ ...apiData, data: { ...apiData.data, status: status } });
      DBRequest({
        config,
        path: `orders/${apiData.data.id}`,
        method: API_PATCH(request),
        onFeedback: handleAlertOpen,
      });
    }
  }, [status]);

  /*
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }
  function handleCopy() {
    const dataCopied = {};
    dataCopied.positions = apiData.data.positions;
    dataCopied.note = apiData.data.note;
    dataCopied.title = apiData.data.title;
    setStartData(dataCopied);
    setOpen(true);
  }
  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }
  */

  // resend mail
  const [openResendMail, setOpenResendMail] = useState(false);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <CardGrid>
      {!apiData ? (
        <OrderSkeleton />
      ) : (
        <>
          <ExoResendMail
            recipient={apiData.data.recipient}
            type="orders"
            id={apiData.data.id}
            open={openResendMail}
            onClose={() => setOpenResendMail(false)}
          />
          <TextCard text={apiData.data.title} span={4} />
          <PictureNameCard
            title={t("Recipient")}
            pictureUrl={
              apiData.data.recipient.logo &&
              apiData.data.recipient.logo.thumbnailUrl
            }
            name={
              apiData.data.recipient.firstName
                ? apiData.data.recipient.firstName +
                  " " +
                  apiData.data.recipient.lastName
                : apiData.data.recipient.name
            }
            colorPool={apiData.data.colorPool}
          />
          <CardContainer>
            <Box className="flex flex-col justify-center items-center gap-2 p-4 rounded-lg">
              <Box className="flex flex-row gap-2">
                <Typography variant="p">{t("Order Nr")}</Typography>
                <Typography variant="h5" className=" monospace">
                  {apiData.data.nr}
                </Typography>
              </Box>
              <Box className="flex flex-row gap-2">
                <Typography variant="p">{t("Start Date")}</Typography>
                <Typography variant="h5" className="monospace">
                  <DateLabel date={apiData.data.startDate} />
                </Typography>
              </Box>
              <Box className="flex flex-row gap-2">
                <Typography variant="p">{t("Delivery Date")}</Typography>
                <Typography variant="h5" className="monospace">
                  <DateLabel date={apiData.data.deliveryDate} />
                </Typography>
              </Box>
              <FormControl fullWidth>
                <InputLabel id="status-select-label">{t("Status")}</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={0}>{t("Running")}</MenuItem>
                  <MenuItem value={1}>{t("Finished")}</MenuItem>
                  <MenuItem value={2}>{t("Cancled")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </CardContainer>

          <FileCard
            files={[
              {
                label: t("Order"),
                icon: <DownloadIcon />,
                url: apiData.data.file && apiData.data.file.temporaryUrl,
              },
            ]}
          />

          <PictureNameCard
            title={t("Supervisor")}
            pictureUrl={
              apiData.data.authorPicture &&
              apiData.data.authorPicture.thumbnailUrl
            }
            name={apiData.data.author}
            colorPool={apiData.data.colorPool}
          />

          <CardContainer
            sx={{
              height: "100vh-600px",
              display: "block",
              padding: "0px",
            }}
            span={999}
          >
            <DisplayPositions
              positions={apiData.data.positions}
              showSum={false}
              columns={columns}
            />
          </CardContainer>
          {apiData.data.notice && (
            <CardContainer>{apiData.data.notice}</CardContainer>
          )}
        </>
      )}
      <Feedback setState={setAlertState} state={alertState} />
    </CardGrid>
  );
};

const OrderSkeleton = () => {
  return (
    <>
      <SkeletonCard height={54} span={4} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={120} span={4} />
    </>
  );
};

export default ExtendOrder;
