// --------------------------------------------------------------------------------
// EVERY ROW NEEDS A ID!!!
// you need to set the height of the parent container!!!
// --------------------------------------------------------------------------------
// data is the whole responseobject not data.data
// columns needs a columns array of objects with the following parameters
/*
  const Columns = [
    {
      header: "Identifier", // header of column
      key: "id", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => <h1>{row.id}</h1>, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
      hiddenDesktop: false, // hides the column on desktop
      onClick: (row, column) => console.log(row, column), // function on cell click
    },
  ];
*/
// isLoading is the state of the apirequest
// extendElement is the element that is displayed below on expand. you get the row as object.
// --------------------------------------------------------------------------------
// example
/*
  <ExoTable
    data={data}
    isLoading={isLoading}
    columns={Columns}
    extendElement={({ row, onUpdate }) => (
      <ExtendElement row={row} onUpdate={onUpdate} />
    )}
  />
*/
// --------------------------------------------------------------------------------
import { useTheme, Box, Skeleton, Collapse, IconButton } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Pagination from "../special/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { API_GET, DBRequest, DBRequestBlank, useConfig } from "../../api/api";
import NoDataAnimation from "../animation/NoDataAnimation";

const ExoTable = forwardRef(
  (
    {
      data,
      columns,
      isLoading,
      extendElement,
      noPagination,
      noBackground,
      className,
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const [tableData, setTableData] = useState(data || {});
    const [reloading, setReloading] = useState(isLoading);
    const [sort, setSort] = useState({
      order: true,
      name: "",
      filter: "",
    });

    useEffect(() => {
      if (!isLoading) {
        setTableData(data);
        setReloading(false);
      }
    }, [isLoading, data]);

    const config = useConfig();

    function sortData(name) {
      setReloading(true);
      var newOrder = sort.order;
      var newName = name;
      var newFilter = sort.filter;
      var newPerPage = 50;
      if (name !== sort.name) newOrder = true;
      if (name === sort.name) newOrder = !sort.order;
      setSort({ order: newOrder, name: newName, filter: newFilter });

      DBRequest({
        config,
        customPath: true,
        path: `${tableData.meta.path}?sortOrder=${
          newOrder ? "asc" : "desc"
        }&sortBy=${newName}${
          newFilter === "" ? "" : "&" + newFilter
        }&perPage=${newPerPage}`,
        method: API_GET,
        onResponse: setTableData,
        onLoading: setReloading,
      });
    }

    function handleUpdate(newRow) {
      // check if its existing
      const existingElement = tableData.data.find(
        (obj) => obj.id === newRow.id
      );
      if (existingElement) {
        const newTableData = tableData.data.map((obj) =>
          obj.id === newRow.id ? newRow : obj
        );
        setTableData({ ...tableData, data: [...newTableData] });
      } else {
        const newTableData = [newRow, ...tableData.data];
        setTableData({ ...tableData, data: [...newTableData] });
      }
    }

    function handleDelete(oldRow) {
      const newTableData = tableData.data.filter(
        (item) => item.id !== oldRow.id
      );
      setTableData({ ...tableData, data: [...newTableData] });
    }

    function handleCreate(newRow, oldRow) {
      if (oldRow) {
        const newTableData = tableData.data.filter(
          (item) => item.id !== oldRow.id
        );
        setTableData({ ...tableData, data: [...newTableData, newRow.data] });
      } else {
        setTableData({ ...tableData, data: [...tableData.data, newRow.data] });
      }
    }

    // exposed functions
    useImperativeHandle(ref, () => ({
      setData: setTableData,
      addRow: handleCreate,
      addRows: (newRows) =>
        setTableData({
          ...tableData,
          data: [...tableData.data, ...newRows.data],
        }),
      updateRow: handleCreate,
      deleteRow: handleDelete,
    }));

    return (
      <Box
        className={
          ` rounded-lg overflow-hidden h-full  flex flex-col ${className} ` +
          (noBackground ? "" : " backdrop-blur-md")
        }
        sx={{
          backgroundColor: noBackground ? "transparent" : colors.glass,
          "& td": {
            padding: "5px 10px",
          },
          "& tr:hover:not(.extendedTable) td": {
            backgroundColor: colors.glass,
          },
        }}
      >
        <Box className="overflow-auto relative flex flex-col h-full">
          <table className="w-full" cellPadding={"0px"}>
            <tbody>
              <tr className=" h-1">
                {extendElement && (
                  <Header
                    column={{
                      header: "", // header of column
                      key: "", // key of column in row-object
                      thAlign: "left", // header text alignment
                      tdAlign: "center", // cell text alignment
                      width: "35px", // width of column
                    }}
                    onSort={sortData}
                  />
                )}
                {columns.map((column, index) => (
                  <Header
                    key={column.key + "-" + index}
                    column={column}
                    onSort={sortData}
                  />
                ))}
              </tr>
              {reloading ? (
                <>
                  {[...Array(30)].map((e, i) => (
                    <tr key={"skel" + i}>
                      <td colSpan={8}>
                        <Skeleton
                          sx={{ transform: "scale(1)" }}
                          className="w-full"
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {!tableData.data.length && (
                    <Box
                      className=" absolute w-full flex justify-center items-center"
                      sx={{ height: "calc(100% - 38px)" }}
                    >
                      <NoDataAnimation />
                    </Box>
                  )}
                  {tableData.data.map((row, index) => (
                    <Row
                      key={"row-" + row.key + "-" + index}
                      row={row}
                      columns={columns}
                      extendElement={extendElement}
                      handleUpdate={handleUpdate}
                      handleDelete={handleDelete}
                      handleCreate={handleCreate}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Box>
        {!noPagination && (
          <Pagination
            data={tableData}
            setData={setTableData}
            setLoading={setReloading}
            filter={sort}
          />
        )}
      </Box>
    );
  }
);

const Header = ({ column, onSort }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleClick() {
    if (!column.sortable) return;
    onSort(column.key);
  }

  var cssClass = "";
  if (column.sortable) cssClass += "cursor-pointer";
  if (column.hiddenDesktop) cssClass += " lg:hidden ";
  if (column.hiddenMobile) cssClass += " hidden lg:table-cell";

  return (
    <th
      onClick={handleClick}
      className={cssClass + " "}
      style={{
        minWidth: column.width ? column.width : "auto",
        width: column.width ? column.width : "auto",
        maxWidth: column.width ? column.width : "auto",
        position: "sticky",
        top: "0",
        zIndex: "10",
        height: "inherit",
      }}
    >
      <Box
        className="h-full w-full flex px-2 py-1 font-semibold backdrop-blur-lg"
        sx={{
          borderBottom: "2px solid " + colors.primary[400] + "!important",
          backgroundColor: colors.glass,
        }}
      >
        <Box
          className="block w-full"
          sx={{
            textAlign: column.thAlign,
          }}
        >
          {column.header}
        </Box>
      </Box>
    </th>
  );
};

const Row = ({
  row,
  columns,
  extendElement,
  handleUpdate,
  handleDelete,
  handleCreate,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [open, setOpen] = useState(false);

  function handleClick(column) {
    if (!column.onClick) return;
    column.onClick(row, column);
  }

  function onDelete(item) {
    setOpen(false);
    handleDelete(item);
  }

  function onCreate(item1, item2) {
    //setOpen(false);
    handleCreate(item1, item2);
  }

  return (
    <>
      <tr>
        {extendElement && (
          <td style={{ padding: "2px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </td>
        )}
        {columns.map((column, index) => (
          <td
            onClick={() => handleClick(column)}
            className={
              (column.hiddenDesktop ? "lg:hidden " : "") +
              (column.hiddenMobile ? "hidden lg:table-cell " : "") +
              (column.onClick ? "cursor-pointer" : "")
            }
            style={{
              textAlign: column.tdAlign,
            }}
            key={"col-" + column.key + "-row-" + row.id + "-" + index}
          >
            {column.cell ? column.cell({ row: row }) : row[column.key]}
          </td>
        ))}
      </tr>

      {extendElement && (
        <tr className="extendedTable">
          <td
            style={{
              padding: "0px",
            }}
            colSpan={1000}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="p-0 sm:p-4">
                <Box className="sm:rounded-lg p-1">
                  {extendElement({
                    row: row,
                    onUpdate: handleUpdate,
                    onDelete,
                    onCreate,
                  })}
                </Box>
              </Box>
            </Collapse>
          </td>
        </tr>
      )}
    </>
  );
};

export default ExoTable;
