import { Box, Dialog, IconButton, Link, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import ExoTable from "../../components/exo/ExoTable";
import ExoAvatar from "../../components/exo/ExoAvatar";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import NewCompany from "../../components/new/NewCompany";

import LocalizedStrings from "react-localization";
import ContactSpeedDialMenu from "../../components/special/ContactSpeedDialMenu";
import ExtendCompany from "./ExtendCompany";
import updateViaId from "../../components/special/updateViaId";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../components/label/MoneyLabel";
import ExoDialog from "../../components/exo/ExoDialog";
import useFetch from "../../api/useFetch";

const Companys = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch(`companies`);

  const columns = [
    {
      header: t("Company Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "130px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <Box className="monospace">{row.nr}</Box>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Company Name"), // header of column
      key: "nameShort", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="flex flex-row justify-start items-center gap-2">
          <ExoAvatar
            picture={row.logo && row.logo.thumbnailUrl}
            name={row.nameShort}
            size="35px"
            type="company"
          />
          {row.nameShort}
        </Box>
      ),
    },
    {
      header: t("Phone"), // header of column
      key: "phone", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "60px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => (
        <Link href={"tel:" + row.phone}>
          <IconButton>
            <LocalPhoneIcon />
          </IconButton>
        </Link>
      ),
    },
    {
      header: t("Mail"), // header of column
      key: "mail", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "70px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => (
        <Link href={"mailto:" + row.mail}>
          <IconButton>
            <EmailIcon />
          </IconButton>
        </Link>
      ),
    },
    {
      header: t("Website"), // header of column
      key: "url", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "80px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => (
        <Link href={row.url} target="_blank">
          <IconButton>
            <LanguageIcon />
          </IconButton>
        </Link>
      ),
    },
    {
      header: t("Total Sales"), // header of column
      key: "salesTotal", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "130px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <MoneyLabel money={row.salesTotal} />,
    },
    {
      header: t("Contact"), // header of column
      key: "id", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "120px", // width of column
      hiddenDesktop: true,
      cell: ({ row }) => (
        <ContactSpeedDialMenu
          tel={row.phone}
          mail={row.mail}
          website={row.url}
        />
      ),
    },
  ];

  // new Company
  const [open, setOpen] = useState(false);
  function handleNewCompany(response) {
    var newCompanies = apiData;
    newCompanies.data.unshift(response.data);
    setApiData(newCompanies);
  }

  function handleUpdatedCompany(updatedCompany) {
    const newCompanies = updateViaId(apiData.data, updatedCompany.data);
    setApiData({ ...apiData, data: newCompanies });
  }

  return (
    <>
      <Header
        title={t("Companies")}
        subtitle="Here you can find all companies."
      />

      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Company")}
          icon={<AddBusinessIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <ExoTable
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendCompany row={row} onUpdate={onUpdate} onDelete={onDelete} />
        )}
        columns={columns}
        data={apiData}
        isLoading={Boolean(!apiData)}
      />
      <ExoDialog open={open} limitWidth>
        <NewCompany
          fullWidth
          onClose={() => setOpen(false)}
          onCreate={handleNewCompany}
          onUpdate={handleUpdatedCompany}
        />
      </ExoDialog>
    </>
  );
};

export default Companys;
