import React, { useState, useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import {
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
  SelectionState,
  Modifier,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
//styling
import StyledTextContainer from "../preview/StyledTextContainer";
import { Headings } from "./styling-controls/BlockStyle";
import { Inline } from "./styling-controls/InlineStyle";

import { ImageStyle } from "./styling-controls/ImageStyle";
import { Editor } from "contenido";
import ImageElement from "./styling-controls/ImageElement";

const RichTextEditor = ({
  setHTML,
  startHTML,
  editorRef,
  setText = () => {},
  className,
  onFocus = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [isFocused, setIsFocused] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(startHTML || "")
    );
    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    if (!startHTML) return;
    const newContentState = ContentState.createFromBlockArray(
      convertFromHTML(startHTML)
    );
    setEditorState(EditorState.createWithContent(newContentState));
  }, [startHTML]);

  const handleImageResize = (entityKey, newDimensions) => {
    let newEditorState = editorState;

    // Get the current content state
    const currentContentState = newEditorState.getCurrentContent();
    // Get the entity corresponding to the provided entity key
    const entity = currentContentState.getEntity(entityKey);
    // Get the current entity data
    const { type, mutability, data } = entity;

    // Create new entity data with updated width and height
    const newData = {
      ...data,
      width: newDimensions.width + "px",
      height: newDimensions.height + "px",
    };

    // Merge the updated entity data into the current content state
    const updatedContentState = currentContentState.mergeEntityData(
      entityKey,
      newData
    );

    // Push the updated content state to the editor state
    newEditorState = EditorState.push(
      newEditorState,
      updatedContentState,
      "apply-entity"
    );

    // Set the new editor state
    setEditorState(newEditorState);
    prepairHTML(newEditorState);
  };

  useEffect(() => {
    prepairHTML(editorState);
  }, [editorState]);

  function prepairHTML(editState) {
    const rawHTML = stateToHTML(editState.getCurrentContent());
    const regex = /(<([^>]+)>)/gi;
    setText(rawHTML.replace(regex, ""));
    setHTML(rawHTML);
  }

  const toggleBlockType = (blockType) => {
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    setEditorState(
      EditorState.forceSelection(newEditorState, editorState.getSelection())
    );
  };

  const toggleInline = (inlineType) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, inlineType);
    setEditorState(
      EditorState.forceSelection(newEditorState, editorState.getSelection())
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
    onFocus(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onFocus(false);
  };

  // custom types
  const customBlockRenderer = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: (props) => (
          <ImageElement
            {...props}
            onResize={handleImageResize}
            isFocused={isFocused}
          />
        ),
        editable: false,
        props: {},
      };
    }
    return null;
  };

  const handleEnter = (editorState, onChange, e) => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      // If there's a selection, let Draft.js handle Enter normally
      return "not-handled";
    }

    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);

    if (block.getType() === "atomic") {
      e.preventDefault();

      // Insert a new empty block after the current block
      const newContentState = Modifier.splitBlock(contentState, selection);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "split-block"
      );
      onChange(newEditorState);

      return "handled";
    }

    return "not-handled";
  };

  return (
    <Box className="flex flex-col gap-2 relative w-full h-full overflow-hidden">
      <Toolbar>
        <Inline editorState={editorState} onToggle={toggleInline} />
        <Headings editorState={editorState} onToggle={toggleBlockType} />
        <ImageStyle editorState={editorState} setEditorState={setEditorState} />
      </Toolbar>
      <StyledTextContainer
        fullWidth
        backgroundColor="transparent"
        className="flex flex-col h-full w-full"
      >
        <Box
          className="h-full w-full "
          sx={{
            padding: "15px!important",
            borderRadius: "10px",
            backgroundColor: colors.bgInput,
            "& .public-DraftEditor-content, & .DraftEditor-editorContainer, & .DraftEditor-root":
              {
                height: "100%",
                minHeight: "300px",
              },
          }}
        >
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            editorRef={editorRef}
            blockRendererFn={customBlockRenderer}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Box>
      </StyledTextContainer>
    </Box>
  );
};

const Toolbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className="rounded-lg p-2 flex flex-row justify-start items-center sticky top-0 z-10 gap-2 overflow-x-auto w-full overflow-y-hidden backdrop-blur-sm"
      sx={{ backgroundColor: colors.bgInput, minHeight: "53px" }}
    >
      {props.children}
    </Box>
  );
};

export default RichTextEditor;
