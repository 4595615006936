import { Box, useTheme } from "@mui/material";
import FormFields from "../FormFields";
import { useEffect, useRef, useState } from "react";
import ExoForm from "../../exo/ExoForm";
import { tokens } from "../../../global/theme/tokens";
import findDifferences from "../../exo/findDifferencesInDataCollection";
import { useTranslation } from "react-i18next";

const TypeReceiptPosition = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  onPreventEnter,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const fields = [
    {
      label: t("Position"),
      key: "position",
      type: "text",
      colspan: "2",
    },
    {
      label: t("Cost Center"),
      type: "costCenter",
      key: "costCenter",
      colspan: "2",
    },
    {
      label: t("Tax Account"),
      key: "taxAssignmentId",
      type: "taxAssignmentId",
    },
    {
      label: t("Tax Rate"),
      key: "taxRate",
      type: "text",
      unit: "%",
    },
    {
      label: t("Price"),
      key: "amount",
      type: "money",
    },
    {
      label: `${t("Comment")} (${t("optional")})`,
      key: "note",
      type: "richText",
    },
  ];

  const [reciept, setReciept] = useState({});

  /*
  useEffect(() => {
    // check if the dataCollections data is different than the current receipt
    if (!dataCollection[field.key]) {
      setReciept({});
      return;
    }
    const diff = findDifferences(reciept, dataCollection[field.key]);
    if (diff && Object.keys(diff).length === 0) return;

    // update the current reciept
    setReciept(dataCollection[field.key]);
  }, [dataCollection[field.key]]);
*/
  function handleChange(data) {
    //setReciept({ ...reciept, ...data });
    updateValidationOnChange(field.key, {
      ...dataCollection[field.key],
      ...data,
    });
  }

  return (
    <Box className="w-full rounded-lg grid grid-cols-2 gap-4">
      <FormFields
        fields={fields}
        dataCollection={dataCollection[field.key]}
        updateDataCollection={handleChange}
        onPreventEnter={onPreventEnter}
      />
    </Box>
  );
};

export default TypeReceiptPosition;
