import { useTheme, Box, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../global/theme/tokens";
import BlockIcon from "@mui/icons-material/Block";
import PaidIcon from "@mui/icons-material/Paid";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";
import { ExoTableToolbarItem } from "../exo/ExoTableToolbar";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const SelectPositions = ({
  positions,
  onSelect,
  isLoading = false,
  allowAll,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  // actions
  const [checkedItems, setCheckedItems] = useState([]);

  const handleClick = (item) => {
    if (!allowAll && (item.status === 1 || item.status === 2)) return;
    var newCheckedItems = [];
    if (checkItemIsSelected(item)) {
      newCheckedItems = checkedItems.filter((obj) => obj.id !== item.id);
    } else {
      newCheckedItems = [...checkedItems, item];
    }

    setCheckedItems(newCheckedItems);
    onSelect(newCheckedItems);
  };

  function checkItemIsSelected(item) {
    const requestedItem = checkedItems.find((obj) => obj.id === item.id);

    return Boolean(requestedItem);
  }

  function selectAll() {
    if (allowAll) {
      setCheckedItems(positions);
      return onSelect(positions);
    }
    const newSelectedPositions = positions.filter(
      (obj) => obj.status !== 1 && obj.status !== 2
    );
    setCheckedItems(newSelectedPositions);
    onSelect(newSelectedPositions);
  }

  return (
    <Box className="w-full flex flex-col gap-2">
      {isLoading ? (
        <>
          {[...Array(30)].map((e, i) => (
            <Skeleton
              key={"pos-skeleton-" + i}
              height={37}
              sx={{ transform: "scale(1)", borderRadius: "9999px" }}
            />
          ))}
        </>
      ) : (
        <>
          <ExoTableToolbarItem
            text={t("Select All")}
            icon={<DoneAllIcon />}
            onClick={selectAll}
          />
          {positions.map((element) => (
            <Position
              allowAll={allowAll}
              key={"pos-" + element.id}
              element={element}
              onClick={handleClick}
              selected={checkItemIsSelected(element)}
            />
          ))}
        </>
      )}
    </Box>
  );
};

const Position = ({ element, onClick, selected, allowAll }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  return (
    <Box
      onClick={() => onClick(element)}
      className="py-2 px-4 flex justify-between items-center rounded-lg"
      sx={{
        backgroundColor: selected ? colors.selected : colors.bgInput,
        cursor: !allowAll && element.status ? "not-allowed" : "pointer",
      }}
    >
      <Typography className="flex gap-2">
        {element.name}
        {element.status == 1 && (
          <>
            <PaidIcon sx={{ color: colors.greenAccent[500] }} />
            <b style={{ color: colors.greenAccent[500] }}>{t("settled")}</b>
          </>
        )}
        {element.status == 2 && (
          <>
            <BlockIcon sx={{ color: colors.redAccent[500] }} />
            <b style={{ color: colors.redAccent[500] }}>{t("cancled")}</b>
          </>
        )}
      </Typography>

      <Box
        className="monospace text-right min-w-fit"
        sx={{
          textDecoration:
            !allowAll && (element.status == 1 || element.status == 2)
              ? "line-through"
              : "none",
        }}
      >
        <MoneyLabel
          money={parseFloat(element.amount * element.price).toFixed(2)}
        />
      </Box>
    </Box>
  );
};

export default SelectPositions;
