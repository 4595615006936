import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionController from "./DescriptionController";

const EntryNameEditable = ({
  col,
  items,
  index,
  onChange,
  setItems,
  onFocus,
  descriptionVars,
  showVars,
}) => {
  const textFieldRef = useRef(null);

  function handleFocus() {
    onFocus(textFieldRef);
  }

  const [expanded, setExpanded] = useState(false);
  // accordion
  const handleIconClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{
        backgroundImage: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
        borderRadius: "6px!important",
        "& .Mui-focusVisible": {
          backgroundColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        height={20}
        sx={{
          margin: "0px",
          padding: "0px 5px",
          minHeight: "29px!important",
          height: "29px!important",
          backgroundColor: "transparent",
        }}
        expandIcon={<ExpandMoreIcon onClick={handleIconClick} />}
      >
        <Box className="w-full">
          <FormControl fullWidth sx={{ m: 0 }}>
            <Input
              ref={textFieldRef}
              onFocus={handleFocus}
              size="small"
              className={col.className}
              id={"pos-input-" + col.key + "-" + index}
              value={items[index][col.key]}
              onChange={onChange}
              endAdornment={
                <InputAdornment position="end">{col.unit}</InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0" }}>
        <DescriptionController
          col={col}
          index={index}
          setItems={setItems}
          items={items}
          descriptionVars={descriptionVars}
          showVars={showVars}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EntryNameEditable;
