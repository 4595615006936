import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { tokens } from "../../global/theme/tokens";
import Loader from "../../components/special/Loader";
import { useState } from "react";
import { API_POST_NO_TOKEN, DBRequest, useConfig } from "../../api/api";
import * as yup from "yup";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import ExoForm from "../../components/exo/ExoForm";
import Cookies from "universal-cookie";

export const SetPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const cookies = new Cookies();
  const colorMode = cookies.get("colorMode");
  const { t } = useTranslation();

  const { email, token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleFormSubmit = (data) => {
    DBRequest({
      config,
      customPath: true,
      path: config.API_PWRESET,
      method: API_POST_NO_TOKEN({ ...data, email, token }),
      onResponse: handleResponse,
      onError: handleResetFailed,
      onLoading: setIsLoading,
    });
  };

  function handleResetFailed(error, status) {
    setErrMsg(`${error.message}`);
  }

  function handleResponse() {
    window.location.replace("/");
  }

  const fields = [
    {
      label: t("New Password"),
      key: "password",
      type: "password",
    },
    {
      label: t("New Password Confirmed"),
      key: "passwordConfirmation",
      type: "password",
    },
  ];

  const checkoutSchema = yup.object().shape({
    password: yup.string().min(8).required(t("Password is required")),
    passwordConfirmation: yup
      .string()
      .min(8)
      .required(t("Password confirmation is required")),
  });

  return (
    <Box className="h-full flex justify-center items-center relative">
      <img
        src={
          colorMode === "dark"
            ? "../../assets/uploads/bg-login-dark-2.jpg"
            : "../../assets/uploads/bg-login-bright-1.jpg"
        }
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "0",
          left: "0",
          objectPosition: "32%",
        }}
      />
      <Loader active={isLoading} type="modern" />
      <Box
        sx={{ bgcolor: colors.glass }}
        className=" backdrop-blur-lg rounded-3xl w-80 p-4 md:w-90 flex flex-col gap-5 justify-center"
      >
        <img
          src={
            colorMode === "dark"
              ? "../../assets/svg/logo.svg"
              : "../../assets/svg/logo-dark.svg"
          }
          className="w-60 h-16 m-auto "
        />
        {errMsg != "" && <Alert severity="error">{errMsg}</Alert>}
        <ExoForm
          validationSchema={checkoutSchema}
          fields={fields}
          submitText={t("Set Password")}
          sx={{
            button: {
              width: "100%",
              backgroundColor: colors.bgInput,
              fontSize: "15px",
            },
          }}
          onSubmit={handleFormSubmit}
        />
      </Box>
    </Box>
  );
};
