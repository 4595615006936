import { useEffect } from "react";
import SelectDelivery from "../select/SelectDelivery";

const StepDelivery = ({
  updateDataCollection,
  dataCollection,
  updateValidation,
  useInvoiceMail,
  recipientKey,
}) => {
  function handleDeliverySelect(delivery) {
    updateDataCollection({ delivery: delivery });
  }

  // validation
  useEffect(() => {
    updateValidation(dataCollection.delivery !== undefined);
  }, [dataCollection]);

  return (
    <SelectDelivery
      useInvoiceMail={useInvoiceMail}
      onSelect={handleDeliverySelect}
      recipient={dataCollection[recipientKey || "recipient"]}
      allowCC
    />
  );
};

export default StepDelivery;
