import { Box } from "@mui/material";
import { useEffect } from "react";
import SelectOrder from "../../components/select/SelectOrder";
import { Controls } from "../../components/exo/ExoStepper";

const StepOrder = ({
  totalAmount,
  apiUrl,
  dataCollection,
  updateDataCollection,
  activeStep,
  setActiveStep,
  step,
  updateValidation,
  button,
  onClose = () => {},
}) => {
  function handleOrderSelect(order) {
    const insertData = {
      invoiceType: "order",
      order: order,
      recipient: order.recipient,
      recipientType: order.recipientType,
      title: order.title,
    };
    updateDataCollection(insertData);

    //next step
    setActiveStep(activeStep + 1);
  }

  useEffect(() => {
    if (dataCollection && dataCollection.order) updateValidation(true);
  }, []);

  return (
    <SelectOrder
      totalAmount={totalAmount}
      apiUrl={apiUrl}
      onSelect={handleOrderSelect}
      filter={apiUrl ? "" : "status[eq]=0"}
      selected={dataCollection && dataCollection.order}
      button={button}
    />
  );
};

export default StepOrder;
