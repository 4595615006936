import { Dialog, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { addImage } from "contenido";
import ExoExplorer from "../../exo/ExoExplorer";
import { useState } from "react";
import ExoDialog from "../../exo/ExoDialog";

export const ImageStyle = ({ editorState, setEditorState }) => {
  const [open, setOpen] = useState(false);

  function handleAddImage(image) {
    setOpen(false);
    const imageProps = {
      src: image.temporaryUrl,
      width: "100px",
      height: "100px",
    };
    addImage(editorState, setEditorState, imageProps);
  }

  return (
    <>
      <ToggleButton onClick={() => setOpen(true)}>
        <div className="w-4 h-4 flex justify-center items-center">
          <ImageIcon />
        </div>
      </ToggleButton>
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <ExoExplorer onSelect={handleAddImage} />
      </ExoDialog>
    </>
  );
};
