import { useEffect } from "react";
import ExoPositions from "../exo/ExoPositions";

const StepAdditionalPositions = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
}) => {
  function handlePositionChange(positions) {
    updateDataCollection({ positions: positions });
  }

  //validation
  useEffect(() => {
    var valid = true;

    //check invoice Positions
    if (dataCollection.positions != undefined) {
      dataCollection.positions.forEach((element) => {
        if (
          !element.name ||
          !element.amount ||
          !element.taxRate ||
          !element.price
        )
          valid = false;
      });
    }

    updateValidation(valid);
  }, [dataCollection]);
  return (
    <ExoPositions
      onPositionChange={handlePositionChange}
      startPositions={dataCollection.positions}
    />
  );
};

export default StepAdditionalPositions;
