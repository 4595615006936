import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import NewCustomOrder from "./NewCustomOrder";
import { useTranslation } from "react-i18next";
import ExoDialog from "../exo/ExoDialog";

const NewOrder = ({ open, onClose, orders, setOrders, offerId = 0 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [orderType, setOrderType] = useState(null);

  function handleClose() {
    setOrderType(null);
    onClose();
  }

  useEffect(() => {
    if (offerId > 0) {
      setOrderType("offer");
    }
  }, []);

  return (
    <ExoDialog open={open}>
      <NewCustomOrder
        orders={orders}
        onCreate={setOrders}
        onClose={handleClose}
      />
    </ExoDialog>
  );
};

export default NewOrder;
