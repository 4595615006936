import { TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ContactsIcon from "@mui/icons-material/Contacts";
import MailIcon from "@mui/icons-material/Mail";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CloudIcon from "@mui/icons-material/Cloud";
import SelectContact from "./SelectContact";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";
import ExoForm from "../exo/ExoForm";

const SelectDelivery = ({
  onSelect,
  recipient = {},
  useInvoiceMail,
  defaultType = "recipientMail",
  options = ["recipientMail", "contactMail", "customMail", "saveOnly"],
  allowCC,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [type, setType] = useState("");
  const [ccMails, setCcMails] = useState([]);

  useEffect(() => {
    handleDelivertype("", defaultType);
  }, []);

  function handleDelivertype(event, newType) {
    if (newType) setType(newType);
    if (newType === "contactMail") handleContactMail({});
    if (newType === "customMail") handleCustomMail({});
    if (newType === "recipientMail") {
      handleRecipientMail();
    }
    if (newType === "saveOnly") {
      handleSaveOnly();
    }
  }

  const optionMap = {
    recipientMail: {
      icon: <ContactMailIcon />,
      label: t("Default Mail"),
      value: "recipientMail",
    },
    contactMail: {
      icon: <ContactsIcon />,
      label: t("Contact Mail"),
      value: "contactMail",
    },
    customMail: {
      icon: <AlternateEmailIcon />,
      label: t("Custom Mail"),
      value: "customMail",
    },
    saveOnly: {
      icon: <CloudIcon />,
      label: t("Save Only"),
      value: "saveOnly",
    },
  };

  // handle callback
  function handleRecipientMail() {
    onSelect({
      deliveryType: "recipientMail",
      deliveryMail: useInvoiceMail ? recipient.invoiceMail : recipient.mail,
      deliveryName: recipient.name
        ? recipient.name
        : `${recipient.salutation} ${recipient.firstName} ${recipient.lastName}`,
      cc: ccMails,
    });
  }
  function handleCustomMail(newCustom) {
    onSelect({
      deliveryType: "custom",
      deliveryMail: newCustom.mail,
      deliveryName: newCustom.name,
      cc: ccMails,
    });
  }
  function handleContactMail(contact) {
    onSelect({
      deliveryType: "contact",
      deliveryMail: contact.mail,
      deliveryName: `${contact.salutation} ${contact.firstName} ${contact.lastName}`,
      cc: ccMails,
    });
  }
  function handleSaveOnly() {
    onSelect({
      deliveryType: "saveOnly",
    });
  }
  function handleCCChange(data) {
    setCcMails(data);
  }
  return (
    <Box className="flex flex-col gap-2">
      <ToggleButtonGroup
        value={type}
        exclusive
        onChange={handleDelivertype}
        className="flex flex-col md:flex-row  gap-1"
      >
        {options.map((option) => (
          <ToggleButton
            value={optionMap[option].value}
            sx={{
              border: "none",
              borderRadius: "5px!important",
              bgcolor: colors.bgInput,
            }}
          >
            <Box className="flex flex-row gap-2">
              {optionMap[option].icon}
              {optionMap[option].label}
            </Box>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {type === "recipientMail" && (
        <RecipientMail
          onChange={handleRecipientMail}
          recipient={recipient}
          useInvoiceMail={useInvoiceMail}
        />
      )}
      {type === "contactMail" && (
        <SelectContact onSelect={handleContactMail} name="deliveryContact" />
      )}
      {type === "customMail" && <CustomMail onChange={handleCustomMail} />}
      {type === "saveOnly" && <SaveOnly />}

      {allowCC && type !== "saveOnly" && <MailCC onCCChange={handleCCChange} />}
    </Box>
  );
};

const MailCC = ({ onCCChange }) => {
  const { t } = useTranslation();
  const fields = [
    {
      label: t("CC Mails"),
      key: "cc",
      type: "mailSelect",
    },
  ];

  function handleChange(data) {
    onCCChange(data.cc);
  }
  return (
    <ExoForm
      sx={{ marginTop: "10px" }}
      fields={fields}
      noControls
      onChange={handleChange}
    />
  );
};

const RecipientMail = ({ recipient, useInvoiceMail }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box className="mt-4 flex items-center justify-center flex-col gap-4">
        <MailIcon sx={{ fontSize: "80px" }} />
        <div className="flex flex-col justify-center items-center gap-1">
          <Typography variant="h3">
            {recipient.name
              ? recipient.name
              : recipient.firstName + " " + recipient.lastName}
          </Typography>
          <Typography variant="h5">
            {useInvoiceMail
              ? recipient.invoiceMail
              : recipient.mail || recipient.email}
          </Typography>
        </div>
        <Alert severity="info" className="w-full">
          {t(
            "The document will be automatically sent by e-mail to the official e-mail address of the recipient."
          )}
        </Alert>
      </Box>
    </Box>
  );
};

const CustomMail = ({ onChange }) => {
  const { t } = useTranslation();
  const [custom, setCustom] = useState({});

  function handleCustomMailChange(data) {
    setCustom(data);
    onChange(data);
  }
  return (
    <Box className="mt-4 flex items-center justify-center flex-col gap-4">
      <TextField
        className="w-full"
        label={t("Recipient Name")}
        variant="outlined"
        onChange={(e) => {
          handleCustomMailChange({ ...custom, name: e.target.value });
        }}
        value={custom.name}
      />
      <TextField
        className="w-full"
        label={t("Mail")}
        variant="outlined"
        onChange={(e) => {
          handleCustomMailChange({ ...custom, mail: e.target.value });
        }}
        value={custom.mail}
      />
      <Alert severity="info" className="w-full">
        {t(
          "The offer will be sent automatically by mail to the email address entered above."
        )}
      </Alert>
    </Box>
  );
};

const SaveOnly = () => {
  const { t } = useTranslation();
  return (
    <Box className="mt-4 flex items-center justify-center flex-col gap-4">
      <CloudIcon sx={{ fontSize: "80px" }} />
      <Box className="flex flex-col justify-center items-center gap-1">
        <Typography variant="h3">{t("Save to the cloud only")}</Typography>
      </Box>
      <Alert severity="warning" className="w-full">
        {t(
          "The offer is exclusively created and stored in the cloud. An automatic delivery does not take place."
        )}
      </Alert>
    </Box>
  );
};

export default SelectDelivery;
