import { Box, Table, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../../api/api";
import CardGrid from "../../components/cards/layout/CardGrid";
import PictureNameCard from "../../components/cards/PictureNameCard";
import CardContainer from "../../components/cards/layout/CardContainer";
import { CardContentText } from "../../components/cards/content/CardContentText";
import { CardContentMail } from "../../components/cards/content/CardContentMail";
import { CardContentLink } from "../../components/cards/content/CardContentLink";
import { CardContentPhone } from "../../components/cards/content/CardContentPhone";
import MoneyCard from "../../components/cards/MoneyCard";
import SkeletonCard from "../../components/cards/SkeletonCard";
import AddressCard from "../../components/cards/AddressCard";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../components/exo/ExoDialog";
import NewCompany from "../../components/new/NewCompany";
import useFetch from "../../api/useFetch";

const ExtendCompany = ({ row, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`companies/${row.id}`);

  // menu
  function handleDelete() {
    DBRequest({
      config,
      path: `companies/${apiData.data.id}`,
      method: API_DELETE,
      onResponse: handleDeleteRow,
    });
  }

  function handleDeleteRow(type, message) {
    onDelete(row);
  }

  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  //edit company
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }
  return (
    <CardGrid>
      {!apiData ? (
        <CompanySkeleton />
      ) : (
        <>
          <SpeedDialMenu actions={actions} />
          <PictureNameCard
            span={1}
            pictureUrl={apiData.data.logo && apiData.data.logo.thumbnailUrl}
            name={apiData.data.name}
            type="company"
          />
          <CardCompanyData company={apiData.data} />
          <MoneyCard title={t("Sales")} amount={apiData.data.salesTotal} />
          <AddressCard address={apiData.data.address} />
        </>
      )}
      <ExoDialog open={open} limitWidth>
        <NewCompany
          fullWidth
          onClose={() => setOpen(false)}
          onUpdate={handleUpdate}
          startData={startData}
        />
      </ExoDialog>
    </CardGrid>
  );
};

const CardCompanyData = ({ company }) => {
  const { t } = useTranslation();
  return (
    <CardContainer span={2}>
      <Table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6">{t("Company Nr")}</Typography>
            </td>
            <td>
              <CardContentText text={company.nr} />
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6">{t("Mail")}</Typography>
            </td>
            <td>
              <CardContentMail mail={company.mail} />
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6">{t("Invoice Mail")}</Typography>
            </td>
            <td>
              <CardContentMail mail={company.invoiceMail} />
            </td>
          </tr>
          {company.phone && (
            <tr>
              <td>
                <Typography variant="h6">{t("Phone")}</Typography>
              </td>
              <td>
                <CardContentPhone phone={company.phone} />
              </td>
            </tr>
          )}
          {company.url && (
            <tr>
              <td>
                <Typography variant="h6">{t("Website")}</Typography>
              </td>
              <td>
                <CardContentLink href={company.url} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </CardContainer>
  );
};

const CompanySkeleton = () => {
  return (
    <>
      <SkeletonCard height={186} />
      <SkeletonCard height={186} span={2} />
      <SkeletonCard height={186} />
      <SkeletonCard height={104} />
    </>
  );
};

export default ExtendCompany;
