import { Box, Checkbox, useTheme, FormControlLabel } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import FormHelperText from "../FormHelperText";

const TypeCheckbox = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleChange(value) {
    updateValidationOnChange([field.key], value.target.checked ? "1" : "0");
  }
  return (
    <Box
      sx={{
        height: "53px",
        borderRadius: "10px",
        bgcolor: colors.bgInput,
        borderWidth: "0px",
        padding: "0px 14px",
        borderColor: colors.primary[300],
        ":hover": {
          borderColor: colors.primary[900],
        },
      }}
      className="flex flex-row items-center justify-start"
    >
      <FormControlLabel
        label={field.label}
        control={
          <Checkbox
            onChange={handleChange}
            color="secondary"
            checked={Boolean(parseInt(dataCollection[field.key]))}
          />
        }
      />
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeCheckbox;
