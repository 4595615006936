import { useTheme, Box, Typography, Button } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import CardGrid from "../../components/cards/layout/CardGrid";
import CardContainer from "../../components/cards/layout/CardContainer";
import useFetch from "../../api/useFetch";
import MoneyLabel from "../../components/label/MoneyLabel";
import { InfoTypes } from "../invoices/invoiceTypes";
import InfoCard from "../../components/cards/InfoCard";
import PreviewPositions from "../../components/preview/PreviewPositions";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import DateLabel from "../../components/label/DateLabel";
import moment from "moment";
import MoneyCard from "../../components/cards/MoneyCard";

const ExtendPaymentReminder = ({ row, onUpdate, onClickEdit, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, isLoading, setApiData } = useFetch(
    `dunning-processes/${row.id}`,
    true
  );

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }

  return (
    <Box className="flex flex-col gap-4">
      {apiData && (
        <>
          <InvoiceInfoCards invoice={apiData.data.invoice} />
          <PaymentReminderInfos apiData={apiData.data} />
          <ProcessStatusController
            apiData={apiData.data}
            setApiData={handleUpdate}
          />
        </>
      )}
    </Box>
  );
};

const PaymentReminderInfos = ({ apiData }) => {
  const { t } = useTranslation();

  const daysOver = moment().diff(
    moment(apiData.invoice.paymentDeadline),
    "days"
  );

  const infos = [
    {
      label: t("Invoice overdue"),
      value: `${daysOver} ${t("Days")}`,
    },
    {
      label: t("Process Started"),
      value: <DateLabel date={apiData.processStartedAt} />,
    },
  ];

  return (
    <CardGrid>
      <InfoCard infos={infos} />
      {apiData.paymentReminders.map((reminder) => (
        <PaymentReminder reminder={reminder} key={reminder.id} />
      ))}
    </CardGrid>
  );
};

const PaymentReminder = ({ reminder }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const infos = [
    {
      label: t("New Deadline"),
      value: <DateLabel date={reminder.newDeadline} />,
    },
    {
      label: t("Fees"),
      value: <MoneyLabel money={reminder.fees} />,
    },
    {
      label: t("Sent to"),
      value: reminder.mailLog.receiver,
    },
    {
      label: t("Sent"),
      value: reminder.mailSentAt ? (
        <DateTimeLabel dateTime={reminder.mailSentAt} />
      ) : (
        t("Not Sent")
      ),
    },
  ];

  return (
    <InfoCard
      infos={infos}
      sx={{
        border:
          reminder.mailSentAt === null
            ? `2px solid ${colors.redAccent[400]}`
            : `2px solid ${colors.greenAccent[400]}`,
      }}
    />
  );
};

const InvoiceInfoCards = ({ invoice }) => {
  const { t } = useTranslation();
  const infos = InfoTypes(invoice);
  return (
    <CardGrid>
      <InfoCard infos={infos} />
      <MoneyCard
        amount={invoice.amount}
        title={`${t("Price")} ${t("Netto")}`}
      />
      <CardContainer
        span={3}
        sx={{
          display: "block",
          padding: "0px",
        }}
      >
        <PreviewPositions positions={invoice.positions || []} />
      </CardContainer>
    </CardGrid>
  );
};

const ProcessStatusController = ({ apiData, setApiData }) => {
  const { t } = useTranslation();
  const config = useConfig();

  function startDunningProcess() {
    DBRequest({
      config,
      path: `dunning-processes/${apiData.id}/start-process`,
      method: API_POST(),
      onResponse: setApiData,
      onError: handleError,
    });
  }

  function changeDunningProcess(status) {
    DBRequest({
      config,
      path: `dunning-processes/${apiData.id}`,
      method: API_PATCH({ status }),
      onResponse: setApiData,
      onError: handleError,
    });
  }

  function handleError(err) {
    console.log(err);
  }

  const statusActions = {
    0: {
      label: t("Start Dunning Process"),
      onClick: startDunningProcess,
      color: "success",
    },
    1: {
      label: t("Pause Dunning Process"),
      onClick: () => changeDunningProcess(2),
      color: "warning",
    },
    2: {
      label: t("Continue Dunning Process"),
      onClick: () => changeDunningProcess(1),
      color: "success",
    },
  };

  const controlls = statusActions[apiData.status];

  return (
    <Box className="flex justify-center items-center py-4">
      <Button
        variant="contained"
        color={controlls.color}
        onClick={controlls.onClick}
      >
        {controlls.label}
      </Button>
    </Box>
  );
};

export default ExtendPaymentReminder;
