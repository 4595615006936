import { Box, Button, Dialog, useTheme } from "@mui/material";
import CodeEditor from "./CodeEditor";
import { useEffect, useRef, useState } from "react";
import { API_PATCH, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import TemplatePreview from "../templates/TemplatePreview";
import Loader from "../special/Loader";
import TemplateVariables from "./templateEditor/TemplateVariables";
import Feedback from "../special/Feedback";

const TemplateEditor = ({
  templateData,
  onSave = () => {},
  onClose = () => {},
  open = true,
  onFeedback = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const aceEditorRef = useRef(null);

  const [template, setTemplate] = useState(templateData);
  const [currentKey, setCurrentKey] = useState("content");

  useEffect(() => {
    setTemplate(templateData);
  }, [templateData]);

  // ACTIONS --------
  function handleCodeChange(data) {
    setTemplate({
      ...template,
      [currentKey]: data,
    });
  }

  function handleHeightChange(page, headerHeight, footerHeight) {
    var newStyle = JSON.parse(template.styleJson);

    if (
      newStyle.page["headerP" + page + "Height"] === headerHeight &&
      newStyle.page["footerP" + page + "Height"] === footerHeight
    )
      return;
    newStyle.page["headerP" + page + "Height"] = headerHeight;
    newStyle.page["footerP" + page + "Height"] = footerHeight;

    setTemplate({
      ...template,
      styleJson: JSON.stringify(newStyle),
    });
  }

  function handleSectionSelect(section) {
    setCurrentKey(section);
  }

  const handleClickVariable = (textToInsert) => {
    const currentPos = aceEditorRef.current.editor.getCursorPosition();
    const text = aceEditorRef.current.editor.getValue();
    const beforeText = text.substring(
      0,
      aceEditorRef.current.editor.session.doc.positionToIndex(currentPos)
    );
    const afterText = text.substring(
      aceEditorRef.current.editor.session.doc.positionToIndex(currentPos),
      text.length
    );

    aceEditorRef.current.editor.setValue(beforeText + textToInsert + afterText);
    aceEditorRef.current.editor.clearSelection();
    aceEditorRef.current.editor.moveCursorToPosition(currentPos);
  };

  const config = useConfig();
  function handleSave() {
    DBRequest({
      config,
      path: "templates/" + template.id,
      method: API_PATCH(template),
      onFeedback: onFeedback,
    });
    onSave(template);
    onClose();
  }
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "100%",
          width: "100%",
          minWidth: "350px",
          maxHeight: "100vh",
          height: "100vh",
          padding: "0px",
          overflow: "hidden",
          borderRadius: "0px",
        },
      }}
    >
      {!template.id ? (
        <Loader active={true} message="Template Loading..." />
      ) : (
        <>
          <Box
            className="grid grid-cols-6 grid-rows-4 w-full"
            sx={{ height: "calc(100% - 45px)" }}
          >
            <TemplateVariables
              className="row-start-1 col-span-2 row-span-4 col-start-1"
              variables={template.vars}
              onClick={handleClickVariable}
            />

            <TemplatePreview
              edit={true}
              onSelect={handleSectionSelect}
              data={template}
              width="300"
              className="m-2 row-start-1 col-span-4 col-start-3 overflow-y-auto row-span-2"
              paginate={true}
              scaleable={true}
              background={true}
              onChange={handleHeightChange}
            />

            <CodeEditor
              className="col-span-4 col-start-3 row-start-3 row-span-2"
              editorRef={aceEditorRef}
              data={template[currentKey]}
              setData={handleCodeChange}
              height={500}
            />
          </Box>
          <Box
            className="col-span-full flex justify-between p-2 h-12 row-start-4"
            sx={{
              backgroundColor: colors.primary[400],
            }}
          >
            <Button color="error" onClick={onClose} variant="outlined">
              Cancle
            </Button>
            <Button color="success" variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default TemplateEditor;
