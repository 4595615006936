import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";

const Header = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  if (props.col.hidden == true) return;
  return (
    <th
      style={{
        textAlign: props.col.align ? props.col.align : "left",
        width: props.col.width ? props.col.width : "auto",
        fontWeight: "500",
        padding: "5px 10px",
        borderBottom: "2px solid " + colors.primary[500],
      }}
    >
      <Typography variant="p">{props.children}</Typography>
    </th>
  );
};

export default Header;
