import React, { createContext, useState, useContext, useEffect } from "react";
import { ClockOutComment } from "../../scenes/time-tracking/ClockOutComment";
import { API_GET, API_POST, DBRequest, useConfig } from "../../api/api";
import Loader from "../special/Loader";
import moment from "moment";

const TimeProvider = createContext();

export const AppTimeTrackingProvider = ({ children }) => {
  const config = useConfig();

  const [activeProject, setActiveProject] = useState(null);
  const [secondsIn, setSecondsIn] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // initial
  useEffect(() => {
    // get active project
    DBRequest({
      config,
      path: "activeproject",
      method: API_GET,
      onResponse: handleActiveProject,
    });
  }, [config]);

  function handleActiveProject(response) {
    setActiveProject(response.data);
  }

  // clock in
  function clockIn(project, workPackage) {
    // request clock in
    const data = {
      projectId: project.id,
      workPackageId: workPackage ? workPackage.id : null,
    };
    DBRequest({
      config,
      path: "clockIn",
      method: API_POST(data),
      onError: handleResetData,
    });

    // set clock in time
    const clockInDateTime = moment();
    const formattedclockInDateTime = clockInDateTime.format();

    // set active project
    setActiveProject({
      ...project,
      activeWorkPackage: workPackage,
      clockInTime: formattedclockInDateTime,
    });
  }

  // clock out
  function clockOut() {
    if (Number(config.employee.requireCommentOnClockout)) {
      setOpenComment(true);
    } else {
      handleClockOut("");
    }
  }

  const handleClockOut = (comment) => {
    setOpenComment(false);
    DBRequest({
      config,
      path: "clockOut",
      method: API_POST({ comment }),
      onResponse: handleResetData,
      onLoading: setIsProcessing,
    });
  };

  const handleResetData = () => {
    setActiveProject(null);
  };

  const [openComment, setOpenComment] = useState(false);

  // CLOCK ----------------------
  useEffect(() => {
    // start clock only if there is a active project
    if (!activeProject) return;

    // init loading times (no delay)
    setSecondsIn(updateTimeInSeconds(activeProject.clockInTime));

    // start clock
    const tickInterval = setInterval(() => {
      if (activeProject) {
        setSecondsIn(updateTimeInSeconds(activeProject.clockInTime));
      }
    }, 10000);
    return () => clearInterval(tickInterval);
  }, [activeProject]);

  function updateTimeInSeconds(startDateTime) {
    const timeDiff = moment().diff(moment(startDateTime));
    const seconds = Math.round(timeDiff / 1000);
    return seconds;
  }

  return (
    <TimeProvider.Provider
      value={{
        activeProject,
        setActiveProject,
        clockOut,
        clockIn,
        secondsIn,
      }}
    >
      {children}
      <ClockOutComment
        open={openComment}
        onClose={() => setOpenComment(false)}
        onSubmit={handleClockOut}
      />
      <Loader active={isProcessing} message="Processing..." />
    </TimeProvider.Provider>
  );
};

export const useTimeTracking = () => useContext(TimeProvider);
