import { Snackbar, Alert } from "@mui/material";

const Feedback = ({ state, setState }) => {
  const handleClose = () => {
    setState({ ...state, alertOpen: false });
  };

  const { alertOpen, alertType, alertText } = state;

  return (
    <Snackbar
      sx={{
        zIndex: "9999",
      }}
      open={alertOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Alert onClose={handleClose} severity={alertType} sx={{ width: "100%" }}>
        {alertText}
      </Alert>
    </Snackbar>
  );
};

export default Feedback;
