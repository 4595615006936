import { useTheme, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import Templates from "./Templates";
import TypographyEditor from "../../components/editor/TypographyEditor";
import ExoTabs from "../../components/exo/ExoTabs";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import DBSettingTab from "./DBSettingTab";
import Feedback from "../../components/special/Feedback";
import AccessManager from "./access-manager";
import { t } from "i18next";
import { BankSettings } from "./BankSettings";
import { hasPermissions } from "../../auth/hasPermissions";
import { NetworkStorage } from "./NetworkStorage";
import { Files } from "./Files";

const Settings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "config",
      method: API_GET,
      onResponse: handleConfigSettings,
    });
  }, [config]);

  const startTabs = [
    {
      label: t("Storage"),
      content: <NetworkStorage onFeedback={handleFeedback} />,
      hidden: !hasPermissions("network-storage-setting", "write"),
    },
    {
      label: t("File Types"),
      content: <Files onFeedback={handleFeedback} />,
      hidden: !hasPermissions("file-types-setting", "write"),
    },
    {
      label: t("Templates"),
      content: <Templates onFeedback={handleFeedback} />,
      hidden: !hasPermissions("template-setting", "write"),
    },
    {
      label: t("Typography"),
      content: <TypographyEditor onFeedback={handleFeedback} />,
      hidden: !hasPermissions("typography-setting", "write"),
    },
    {
      label: t("Bank Accounts"),
      content: <BankSettings onFeedback={handleFeedback} />,
      hidden: !hasPermissions("bank-account-setting", "write"),
    },
    {
      label: t("Access"),
      content: <AccessManager />,
      hidden: !hasPermissions("access-setting", "write"),
    },
  ];

  const [tabs, setTabs] = useState(startTabs);

  function handleConfigSettings(data) {
    const settings = data.data;

    var newTabs = [...startTabs];

    settings.forEach((setting) => {
      newTabs.push({
        label: t(setting.label),
        content: (
          <DBSettingTab
            data={setting.items}
            onFeedback={handleFeedback}
            api={setting.api}
          />
        ),
        hidden: !hasPermissions(`${setting.category}-setting`, "write"),
      });
    });
    setTabs(newTabs);
  }

  return (
    <>
      <Header title="Settings" subtitle="You can adjust settings here." />
      <Box className=" flex flex-col h-full overflow-y-auto">
        <ExoTabs tabs={tabs} orientation="vertical" />
        <Feedback state={alertState} setState={setAlertState} />
      </Box>
    </>
  );
};

export default Settings;
