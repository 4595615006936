import EntryNameEditable from "./EntryNameEditable";
import EntryEditable from "./EntryEditable";
import EntryConst from "./EntryConst";

const CustomPosition = ({
  col,
  items,
  index,
  onChange,
  setItems,
  onFocus = () => {},
  descriptionVars,
  showVars,
}) => {
  return (
    <>
      {col.editable && (
        <>
          {col.key === "name" && (
            <EntryNameEditable
              col={col}
              items={items}
              index={index}
              onChange={onChange}
              setItems={setItems}
              onFocus={onFocus}
              descriptionVars={descriptionVars}
              showVars={showVars}
            />
          )}
          {col.key !== "name" && (
            <EntryEditable
              col={col}
              items={items}
              index={index}
              onChange={onChange}
              onFocus={onFocus}
            />
          )}
        </>
      )}
      {!col.editable && (
        <>
          {col.custom && (
            <EntryConst
              unit={""}
              value={col.custom(items[index], col.unit)}
              className={col.className}
            />
          )}
          {!col.custom && (
            <EntryConst
              unit={col.unit}
              value={items[index][col.key]}
              className={col.className}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomPosition;
