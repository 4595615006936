import { Box, colors, useTheme, Typography } from "@mui/material";
import { Toolbar } from "./project/Toolbar";
import { useTranslation } from "react-i18next";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import useFetch from "../../api/useFetch";
import { ProjectPhaseStack } from "./project/phase/ProjectPhaseStack";
import Header from "../special/Header";
import { useState } from "react";
import ExoDialog from "./ExoDialog";
import NewEmptyProject from "../new/NewEmptyProject";
import { useNavigate } from "react-router-dom";
import { hasPermissions } from "../../auth/hasPermissions";
import BarChartIcon from "@mui/icons-material/BarChart";
import { ProjectUserStack } from "./project/ProjectUserStack";
import { tokens } from "../../global/theme/tokens";

export const ExoProject = ({ projectId = 18, allowEditMode }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // project
  const { apiData, setApiData } = useFetch(`projects/${projectId}`);
  const [open, setOpen] = useState(false);

  function handleUpdate(data) {
    setApiData({ ...apiData, data: data });
  }

  // project management
  const [inEditMode, setInEditMode] = useState(false);
  const toollist = [
    {
      Icon: SettingsApplicationsIcon,
      label: t("Settings"),
      onClick: () => setOpen(true),
    },
    {
      Icon: BarChartIcon,
      label: t("Finance"),
      onClick: () => navigate(`/projects/${projectId}`),
      hidden: !hasPermissions("projects-statistics"),
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col mx-auto">
      <Header title={apiData && apiData.data.name} />

      {apiData && apiData.data.editable && (
        <Toolbar
          tools={toollist}
          setInEditMode={setInEditMode}
          inEditMode={inEditMode}
        />
      )}

      {apiData && (
        <ProjectPhaseStack project={apiData.data} editmode={inEditMode} />
      )}
      {apiData && (
        <Box
          className=" p-4 rounded-lg mt-4 backdrop-blur-lg"
          sx={{ bgcolor: colors.glass }}
        >
          <Typography variant="h6" className="pb-2">
            {t("Team")}
          </Typography>
          <Box className="flex">
            <ProjectUserStack
              noLabel
              displayCount={8}
              editmode={apiData.data.editable}
              users={apiData.data.teamMembers}
              api={"users"}
              changeApi={"project/" + apiData.data.id + "/projectassignment"}
              onChange={(res, newMembers) =>
                setApiData({
                  ...apiData,
                  data: { ...apiData.data, teamMembers: newMembers },
                })
              }
            />
          </Box>
        </Box>
      )}
      <ExoDialog open={open}>
        <NewEmptyProject
          onClose={() => setOpen(false)}
          onCreate={handleUpdate}
          startDataCollection={apiData && apiData.data}
        />
      </ExoDialog>
    </Box>
  );
};
