import EntryEditable from "./EntryEditable";
import EntryConst from "./EntryConst";
import EntryNameConst from "./EntryNameConst";

const PredefinedPosition = ({ col, items, index, onChange, setItems }) => {
  return (
    <>
      {col.custom && <>{col.custom(items[index], col.unit)}</>}
      {col.key === "position" && (
        <EntryConst
          value={items[index][col.key]}
          unit=""
          className={col.className}
        />
      )}
      {col.key === "name" && (
        <EntryNameConst
          name={items[index].name}
          description={items[index].description}
          tooltip={items[index].productNr}
          type={items[index].positionableType}
        />
      )}
      {col.key === "unit" && (
        <EntryConst
          value={items[index][col.key]}
          unit=""
          className={col.className}
        />
      )}
      {(col.key === "amount" ||
        col.key === "taxRate" ||
        col.key === "price") && (
        <EntryEditable
          col={col}
          items={items}
          index={index}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default PredefinedPosition;
