import { Box } from "@mui/material";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";

import ExoForm from "../exo/ExoForm";
import * as Yup from "yup";
import findDifferences from "../exo/findDifferencesInDataCollection";
import Loader from "../special/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NewCompany = ({
  onClose = () => {},
  onCreate,
  onUpdate,
  startData,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = [
    {
      label: `${t("Logo")} (${t("optional")})`,
      type: "logo",
      key: "logo",
    },
    {
      label: t("Company Name"),
      type: "text",
      key: "name",
    },
    {
      label: t("Company Name Short"),
      type: "text",
      key: "nameShort",
    },
    {
      label: `${t("Website")} (${t("optional")})`,
      type: "text",
      key: "url",
    },
    {
      label: t("Invoice Mail"),
      type: "text",
      key: "invoiceMail",
    },
    {
      label: t("Mail"),
      type: "text",
      key: "mail",
    },
    {
      label: `${t("Phone")} (${t("optional")})`,
      type: "text",
      key: "phone",
    },
    {
      label: `${t("IBAN")} (${t("optional")})`,
      type: "iban",
      key: "iban",
    },
    {
      label: `${t("Industry")} (${t("optional")})`,
      type: "industryId",
      key: "industryId",
    },
    {
      label: t("Address"),
      type: "address",
      key: "address",
    },
  ];
  const config = useConfig();
  function handleSubmit(data) {
    setIsLoading(true);
    DBRequest({
      config,
      path: startData && startData.id ? `companies/${data.id}` : "companies",
      method:
        startData && startData.id
          ? API_PATCH(findDifferences(startData, data))
          : API_POST(data),
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    setIsLoading(false);
    onClose();
    if (startData && startData.id) {
      onUpdate(data);
    } else {
      onCreate(data);
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Company Name is required")),
    nameShort: Yup.string().required(t("Short Company Name is required")),
    invoiceMail: Yup.string()
      .email(t("Invalid email format"))
      .required(t("Invoice Mail is required")),
    mail: Yup.string()
      .email(t("Invalid email format"))
      .required(t("Mail is required")),
    address: Yup.object().shape({
      street: Yup.string().required(t("Address is required")),
    }),
  });

  return (
    <Box
      className="p-2 max-w-full h-full overflow-y-auto"
      sx={{ width: fullWidth ? "100%" : "500px" }}
    >
      <Loader active={isLoading} />
      <ExoForm
        noDifferences
        onSubmit={handleSubmit}
        fields={fields}
        validationSchema={validationSchema}
        startDataCollection={startData}
        onCancle={() => onClose()}
        header={
          startData && startData.id ? t("Edit Company") : t("New Company")
        }
      />
    </Box>
  );
};

export default NewCompany;
