import { Box } from "@mui/material";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";

import ExoForm from "../exo/ExoForm";
import * as Yup from "yup";
import findDifferences from "../exo/findDifferencesInDataCollection";
import Loader from "../special/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NewContact = ({ onClose, onCreate, onUpdate, startData, fullWidth }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const fields = [
    {
      label: `${t("Color")} (${t("optional")})`,
      type: "color",
      key: "color",
    },
    {
      label: t("Salutation"),
      type: "text",
      key: "salutation",
    },
    {
      label: t("First Name"),
      type: "text",
      key: "firstName",
    },
    {
      label: t("Last Name"),
      type: "text",
      key: "lastName",
    },
    {
      label: `${t("Company")} (${t("optional")})`,
      type: "companyId",
      key: "companyId",
    },
    {
      label: `${t("Website")} (${t("optional")})`,
      type: "text",
      key: "url",
    },
    {
      label: `${t("Position")} (${t("optional")})`,
      type: "text",
      key: "position",
    },
    {
      label: t("Invoice Mail"),
      type: "text",
      key: "invoiceMail",
    },
    {
      label: t("Mail"),
      type: "text",
      key: "mail",
    },
    {
      label: `${t("Phone")} (${t("optional")})`,
      type: "text",
      key: "phone",
    },
    {
      label: t("Address"),
      type: "address",
      key: "address",
    },
  ];
  const config = useConfig();
  function handleSubmit(data) {
    setIsLoading(true);
    DBRequest({
      config,
      path: startData && startData.id ? `contacts/${data.id}` : "contacts",
      method:
        startData && startData.id
          ? API_PATCH(findDifferences(startData, data))
          : API_POST(data),
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    setIsLoading(false);
    onClose();
    if (startData && startData.id) {
      onUpdate(data);
    } else {
      onCreate(data);
    }
  }

  const validationSchema = Yup.object().shape({
    color: Yup.string().required(t("Color is required")),
    salutation: Yup.string().required(t("Name is required")),
    firstName: Yup.string().required(t("Short Name is required")),
    lastName: Yup.string().required(t("Short Name is required")),
    invoiceMail: Yup.string()
      .email(t("Invalid email format"))
      .required(t("Invoice Mail is required")),
    mail: Yup.string()
      .email(t("Invalid email format"))
      .required(t("Mail is required")),
    address: Yup.object().shape({
      street: Yup.string().required(t("Address is required")),
    }),
  });

  return (
    <Box
      className="p-2 max-w-full overflow-y-auto h-full"
      sx={{ width: fullWidth ? "100%" : "500px" }}
    >
      <Loader active={isLoading} />
      <ExoForm
        noDifferences
        onSubmit={handleSubmit}
        fields={fields}
        validationSchema={validationSchema}
        startDataCollection={startData || { color: "#5e9ac8" }}
        onCancle={onClose}
        header={t("New Contact")}
      />
    </Box>
  );
};

export default NewContact;
