import {
  Box,
  useTheme,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { ProjectUserStack } from "../ProjectUserStack";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import MediaPreview from "../../explorer/MediaPreview";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_DELETE, DBRequest, useConfig } from "../../../../api/api";
import { SettingsPopover } from "../SettingsPopover";
import EditIcon from "@mui/icons-material/Edit";
import { ProjectAddElementButton } from "../ProjectAddElementButton";
import { WPEForm } from "../work-package-elements/WPEForm";
import { WPE } from "../work-package-elements/WPE";
import { useState } from "react";
import ExoDialog from "../../ExoDialog";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useTimeTracking } from "../../../time-tracking/AppTimeProvider";
import StopIcon from "@mui/icons-material/Stop";
import { EstimatedTime } from "./EstimatedTime";

export const ProjectWorkPackage = ({
  project,
  workPackage,
  onChange,
  onDelete,
  editmode,
  onEdit,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { clockIn, clockOut, activeProject } = useTimeTracking();

  const [openNewSE, setOpenNewSE] = useState(false);

  const settings = [
    {
      label: t("Edit"),
      Icon: EditIcon,
      onClick: () => onEdit(workPackage),
    },
    {
      label: t("Delete"),
      Icon: DeleteIcon,
      onClick: handleDelete,
      color: "error",
    },
  ];

  function handleDelete() {
    DBRequest({
      config,
      path: `work-packages/${workPackage.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(workPackage),
    });
  }

  function handleNewElement(newElement) {
    const newElements = [...workPackage.elements, newElement];
    const updatedWorkPackage = { ...workPackage, elements: newElements };
    onEdit(updatedWorkPackage);
  }

  function handleWPEDelete(elementToDel) {
    const newElements = workPackage.elements.filter(
      (obj) => obj.id !== elementToDel.id
    );
    onChange({ ...workPackage, elements: newElements });
  }

  return (
    <Box
      className="p-4 relative rounded-lg backdrop-blur-lg flex flex-col justify-between"
      sx={{ bgcolor: colors.glass }}
    >
      <Box>
        <Box className="flex justify-between">
          <Typography variant="h3" className="pb-2 flex items-center gap-4">
            {workPackage.icon && (
              <MediaPreview
                type="ICON"
                src={workPackage.icon.temporaryUrl}
                className="w-8 h-8"
              />
            )}
            {workPackage.title}
          </Typography>
          {!editmode &&
            Boolean(workPackage.isTimeTrackable) &&
            workPackage.allowTimeTracking &&
            (activeProject &&
            activeProject.activeWorkPackage &&
            activeProject.activeWorkPackage.id === workPackage.id ? (
              <Button
                onClick={() => clockOut()}
                sx={{ position: "absolute" }}
                className="top-1 right-1"
                variant="outlined"
                color="error"
              >
                <StopIcon />
              </Button>
            ) : (
              <Button
                onClick={() => clockIn(project, workPackage)}
                sx={{ position: "absolute" }}
                className="top-1 right-1"
                variant="outlined"
                color="success"
              >
                <PlayArrowIcon />
              </Button>
            ))}
          {editmode && <SettingsPopover settings={settings} />}
        </Box>
        <Box className="pb-4">
          <Box className="flex flex-col gap-1">
            {workPackage.elements &&
              workPackage.elements.map((element) => (
                <WPE
                  key={element.id}
                  element={element}
                  mode="preview"
                  editmode={editmode}
                  onDelete={handleWPEDelete}
                />
              ))}
          </Box>
          {editmode && (
            <>
              <ExoDialog open={openNewSE} className="overflow-y-auto">
                <WPEForm
                  workPackage={workPackage}
                  onCreate={handleNewElement}
                  onClose={() => setOpenNewSE(false)}
                  sx={{ maxWidth: "700px", margin: "auto" }}
                />
              </ExoDialog>
              <ProjectAddElementButton onClick={() => setOpenNewSE(true)} />
            </>
          )}
        </Box>
      </Box>

      <Box>
        <Divider />

        <Box className="flex justify-between items-start">
          <EstimatedTime workPackage={workPackage} />

          <ProjectUserStack
            editmode={editmode}
            users={workPackage.users}
            workPackage={workPackage}
            onChange={onChange}
            projectId={project.id}
          />
        </Box>
      </Box>
    </Box>
  );
};
