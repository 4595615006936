import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useEffect, useState } from "react";
import { numberToString } from "../../special/numberConverter";
import { useConfig } from "../../../api/api";
import MoneyLabel from "../../label/MoneyLabel";

const Sum = ({ sum, cols }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const [columnCount, setColumnCount] = useState(null);

  useEffect(() => {
    var newColumnCount = 0;
    cols.forEach((col) => {
      if (!col.hidden) newColumnCount++;
    });
    setColumnCount(newColumnCount);
  }, []);

  return (
    <>
      <SumElement
        colSpan={columnCount}
        sum={sum.netto}
        text="Netto"
        currency={config && config.currency.currencySymbol}
      />

      <SumElement
        colSpan={columnCount}
        sum={sum.tax}
        text="MwSt."
        currency={config && config.currency.currencySymbol}
      />

      <SumElement
        colSpan={columnCount}
        sum={sum.netto + sum.tax}
        text="Brutto"
        currency={config && config.currency.currencySymbol}
      />
    </>
  );
};

const SumElement = ({ colSpan, sum, text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <tr>
      <td colSpan={colSpan - 2}></td>
      <td>
        <Box className="w-full text-right pb-2">{text}</Box>
      </td>
      <td>
        <Box className="w-full text-right monospace pr-1 pb-2">
          <MoneyLabel money={sum} />
        </Box>
      </td>
    </tr>
  );
};

export default Sum;
