import SpeedDialMenu from "../menu/SpeedDialMenu";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WebAssetIcon from "@mui/icons-material/WebAsset";

const ContactSpeedDialMenu = ({ tel, mail, website }) => {
  const actions = [
    {
      icon: <CallIcon />,
      hidden: !Boolean(tel),
      name: "",
      onClick: () => (window.location = `tel:${tel}`),
    },
    {
      icon: <EmailIcon />,
      hidden: !Boolean(mail),
      name: "",
      onClick: () => (window.location = `mailto:${mail}`),
    },
    {
      icon: <WebAssetIcon />,
      hidden: !Boolean(website),
      name: "",
      onClick: () => window.open(website, "_blank"),
    },
  ];

  return (
    <SpeedDialMenu
      actions={actions}
      className="relative float-right"
      direction="left"
      noBlur
      Icon={ConnectWithoutContactIcon}
    />
  );
};

export default ContactSpeedDialMenu;
