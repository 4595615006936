import { Box, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import ResizableBox from "react-resizable-box";
import { tokens } from "../../../global/theme/tokens";

const ImageElement = ({ block, contentState, onResize, isFocused }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { src, width, height } = contentState
    .getEntity(block.getEntityAt(0))
    .getData();
  const entityKey = getEntityKeyForBlock(contentState, block);

  const imgRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: width || 200,
    height: height || 200,
  });
  const resizeTimer = useRef(null);

  if (!entityKey) {
    // Handle case when entity key is null or undefined
    return null;
  }

  const handleResize = () => {
    clearTimeout(resizeTimer.current);
    resizeTimer.current = setTimeout(() => {
      if (imgRef === null) return;
      const width = imgRef.current.offsetWidth;
      const height = imgRef.current.offsetHeight;
      setDimensions({ width, height });
      // Call the callback function with updated dimensions
      onResize(entityKey, { width, height });
    }, 300); // Adjust the debounce time as needed
  };

  const ballSize = 5;

  return (
    <ResizableBox
      style={{
        minWidth: "100px",
        minHeight: "100px",
        maxWidth: "700px",
        maxHeight: "800px",
      }}
      width={dimensions.width}
      className="relative inline-block"
      onResize={handleResize}
    >
      <img
        ref={imgRef}
        src={src}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          outline: `${isFocused ? "1" : "0"}px dashed ${colors.text}`,
        }}
      />
      <Box
        className="absolute rounded-full z-50 "
        sx={{
          bgcolor: colors.text,
          width: ballSize,
          height: ballSize,
          top: -ballSize / 2,
          left: -ballSize / 2,
          display: isFocused ? "block" : "none",
        }}
      />
      <Box
        className="absolute rounded-full z-50 "
        sx={{
          bgcolor: colors.text,
          width: ballSize,
          height: ballSize,
          top: -ballSize / 2,
          right: -ballSize / 2,
          display: isFocused ? "block" : "none",
        }}
      />
      <Box
        className="absolute rounded-full z-50 "
        sx={{
          bgcolor: colors.text,
          width: ballSize,
          height: ballSize,
          bottom: -ballSize / 2,
          left: -ballSize / 2,
          display: isFocused ? "block" : "none",
        }}
      />
      <Box
        className="absolute rounded-full z-50 "
        sx={{
          bgcolor: colors.text,
          width: ballSize,
          height: ballSize,
          bottom: -ballSize / 2,
          right: -ballSize / 2,
          display: isFocused ? "block" : "none",
        }}
      />
    </ResizableBox>
  );
};

const getEntityKeyForBlock = (contentState, block) => {
  const entityKey = block.getEntityAt(0);
  if (entityKey) {
    const entity = contentState.getEntity(entityKey);
    // Do something with the entity, if needed
    return entityKey;
  }
  return null;
};

export default ImageElement;
