import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";
import { useTranslation } from "react-i18next";
import LabeledBox from "../../special/LabeledBox";
import ExoDialog from "../../exo/ExoDialog";
import ExoCostCenter, { costCenterIconMap } from "../../exo/ExoCostCenter";
import ExoAvatar from "../../exo/ExoAvatar";
import DBSearch from "../../special/DBSearch";

const TypeCostCenter = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  value,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (
      dataCollection[field.key] &&
      (!selected || dataCollection[field.key].id !== selected.id)
    ) {
      setSelected(dataCollection[field.key]);
    }
    if (!dataCollection[field.key] && selected) setSelected(null);
  }, [dataCollection[field.key]]);

  function handleChangeSelect(value) {
    setSelected(value);
    updateValidationOnChange(field.key, value);
    setOpen(false);
  }

  return (
    <Box
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "auto",
      }}
    >
      <LabeledBox label={field.label} onClick={() => setOpen(true)}>
        {selected ? (
          <Box className="flex items-center gap-4">
            {costCenterIconMap[selected.type]}
            {selected.name}
          </Box>
        ) : (
          t("No Costcenter Selected")
        )}
      </LabeledBox>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <SelectCostCenter
          types={field.types || ["DIRECT_COSTS"]}
          onSelect={handleChangeSelect}
          onClose={() => setOpen(false)}
        />
      </ExoDialog>
    </Box>
  );
};

const SelectCostCenter = ({ onSelect, onClose, types }) => {
  const [costCenters, setCostCenters] = useState(null);
  const [projects, setProjects] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "cost-centers?type[eq]=0",
      method: API_GET,
      onResponse: setCostCenters,
    });
    DBRequest({
      config,
      path: "projects-cost-center-list?type[eq]=1",
      method: API_GET,
      onResponse: setProjects,
    });
  }, [config]);

  return (
    <Box className="flex flex-col gap-4 overflow-y-auto h-full">
      <DBSearch />
      <IndirectCostCenters costCenters={costCenters} onSelect={onSelect} />
      {projects ? (
        projects.data.map((project) => (
          <ProjectCostCenters
            project={project}
            onSelect={onSelect}
            types={types}
          />
        ))
      ) : (
        <TypeSkeleton n={25} />
      )}
    </Box>
  );
};
const IndirectCostCenters = ({ costCenters, onSelect }) => {
  const { t } = useTranslation();

  return (
    <Box className="flex flex-col gap-2">
      <Typography variant="h4">{t("Indirect Costs")}</Typography>
      <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        {costCenters &&
          costCenters.data.map((costCenter) => (
            <ExoCostCenter
              costCenter={costCenter}
              key={costCenter.id}
              onClick={onSelect}
            />
          ))}
      </Box>
    </Box>
  );
};

const ProjectCostCenters = ({ project, onSelect, types }) => {
  const { t } = useTranslation();
  const client = project.client;
  return (
    <Box className="flex flex-col gap-2">
      <Typography variant="h4" className="flex gap-2 items-center">
        <ExoAvatar
          type={project.clientType}
          size="25px"
          picture={client.logo && client.logo.temporaryUrl}
          name={
            project.clientType === "company"
              ? client.name
              : `${client.firstName} ${client.lastName}`
          }
        />
        {project.name}
      </Typography>
      <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        {project &&
          project.directCostCenters.map((costCenter) => (
            <ExoCostCenter
              onClick={onSelect}
              costCenter={costCenter}
              key={costCenter.id}
              hidden={!types.find((type) => type === costCenter.type)}
            />
          ))}
      </Box>
    </Box>
  );
};

export default TypeCostCenter;
