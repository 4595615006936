import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useState } from "react";
import PopUpTextEditor from "../../../popup/PopUpTextEditor";
import StyledTextContainer from "../../../preview/StyledTextContainer";
import { useTranslation } from "react-i18next";

const DescriptionController = ({
  col,
  index,
  setItems,
  items,
  descriptionVars,
  showVars,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [openEditor, setOpenEditor] = useState(false);
  function handleClick() {
    setOpenEditor(!openEditor);
  }

  function handleSave(text) {
    var newItems = items;
    newItems[index]["description"] = text;
    setItems([...newItems]);
  }

  return (
    <>
      <StyledTextContainer backgroundColor="transparent" fullWidth>
        <Box
          className="mt-2 py-2 pt-2 px-4 rounded-md transition-colors"
          onClick={handleClick}
          sx={{
            ":hover": {
              backgroundColor: colors.glass,
              cursor: "pointer",
            },
            padding: "10px 15px!important",
            border: "1px solid " + colors.primary[400],
            marginTop: "10px!important",
          }}
          dangerouslySetInnerHTML={{
            __html: items[index]["description"]
              ? items[index]["description"]
              : t("No Description"),
          }}
        ></Box>
      </StyledTextContainer>

      <PopUpTextEditor
        open={openEditor}
        onClose={handleClick}
        onSave={handleSave}
        startHTML={items[index]["description"]}
        descriptionVars={descriptionVars}
        showVars={showVars}
      />
    </>
  );
};

export default DescriptionController;
