import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import SelectCompany from "../select/SelectCompany";
import SelectContact from "../select/SelectContact";
import { useTranslation } from "react-i18next";

const StepRecipient = ({
  updateDataCollection,
  dataCollection,
  updateValidation,
  key = "recipient",
}) => {
  const { t } = useTranslation();

  const [type, setType] = useState("company");

  useEffect(() => {
    if (dataCollection[key + "Type"]) {
      updateValidation(dataCollection.recipient !== undefined);
      setType(dataCollection[key + "Type"]);
    }
  }, [dataCollection]);

  function handleDelivertype(event, newType) {
    if (newType) {
      setType(newType);
    }
  }

  function handleCompanySelect(company) {
    const insertData = {
      [key + "Type"]: "company",
      [key]: company,
    };
    updateDataCollection(insertData);
  }

  function handleContactSelect(contact) {
    const insertData = {
      [key + "Type"]: "contact",
      [key]: contact,
    };
    updateDataCollection(insertData);
  }

  return (
    <Box className="h-full">
      <ToggleButtonGroup value={type} exclusive onChange={handleDelivertype}>
        <ToggleButton value="company">
          <Tooltip title={t("Companies")}>
            <ApartmentIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="contact">
          <Tooltip title={t("Contacts")}>
            <ContactsIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        className="pt-2"
        sx={{
          height: "calc(100% - 45px - 4px)",
        }}
      >
        {type === "company" && (
          <SelectCompany
            onSelect={handleCompanySelect}
            preSelected={dataCollection[key]}
          />
        )}
        {type === "contact" && (
          <SelectContact
            onSelect={handleContactSelect}
            preSelected={dataCollection[key]}
          />
        )}
      </Box>
    </Box>
  );
};

export default StepRecipient;
