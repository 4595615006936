import Topbar from "./global/Topbar";
import Sidebar from "./global/Sidebar";
import MobileMenu from "./global/MobileMenu";
import { Box, useTheme } from "@mui/material";
import Cookies from "universal-cookie";
import NewUserPersonalData from "./components/new/NewUserPersonalData";
import Div100vh from "react-div-100vh";
import { DevHighlighting } from "./DevHighlighting";

const Layout = ({ children }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  // get background image
  const cookies = new Cookies();
  const storedBackgroundImageDark = cookies.get("backgroundImageDark")
    ? cookies.get("backgroundImageDark")
    : "/assets/uploads/bg-login-dark-2.jpg";
  const storedBackgroundImageLight = cookies.get("backgroundImageLight")
    ? cookies.get("backgroundImageLight")
    : "/assets/uploads/bg-login-bright-1.jpg";

  return (
    <Div100vh>
      <Box
        className="fixed top-0 left-0 w-full h-full -z-10"
        sx={{
          backgroundImage:
            mode === "dark"
              ? "url(" + storedBackgroundImageDark + ")"
              : "url(" + storedBackgroundImageLight + ")",
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
        }}
      />
      <DevHighlighting className="app flex flex-col-reverse sm:flex-row h-full overflow-hidden relative">
        <Sidebar />
        <MobileMenu />
        <main className="min-h-full md:h-full w-full overflow-auto sm:overflow-hidden flex flex-col pb-9 sm:pb-0">
          <Topbar />
          <Box className="px-0 pt-2 sm:p-4 sm:h-full flex flex-col gap-1 sm:overflow-hidden">
            {children}
          </Box>
        </main>
        <NewUserPersonalData />
      </DevHighlighting>
    </Div100vh>
  );
};

export default Layout;
