import {
  useTheme,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import Feedback from "../../components/special/Feedback";
import { API_PATCH, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import PreviewPositions from "../../components/preview/PreviewPositions";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import TextCard from "../../components/cards/TextCard";
import CardGrid from "../../components/cards/layout/CardGrid";
import PictureNameCard from "../../components/cards/PictureNameCard";
import FileCard from "../../components/cards/FilesCard";
import DownloadIcon from "@mui/icons-material/Download";
import CardContainer from "../../components/cards/layout/CardContainer";
import PopUpContainer from "../../components/popup/PopUpContainer";
import Share from "../../components/special/Share";
import RenderHTML from "../../components/preview/RenderHTML";
import StyledTextContainer from "../../components/preview/StyledTextContainer";
import SkeletonCard from "../../components/cards/SkeletonCard";
import { useTranslation } from "react-i18next";
import ImagesCard from "../../components/cards/ImagesCard";
import ExoResendMail from "../../components/exo/ExoResendMail";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoCard from "../../components/cards/InfoCard";
import { InfoTypes } from "./invoiceTypes";
import useFetch from "../../api/useFetch";
import NewInvoice from "../../components/new/NewInvoice";

const ExtendInvoice = ({ row, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch(`invoices/${row.id}`);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  const [startData, setStartData] = useState(null);
  const [open, setOpen] = useState(false);

  // menu
  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    {
      icon: <ContentCopyIcon />,
      name: t("Copy"),
      onClick: handleCopy,
    },
    {
      icon: <ForwardToInboxIcon />,
      name: t("Send Again"),
      onClick: () => setOpenResendMail(true),
    },
    {
      icon: <PrintIcon />,
      name: t("Print"),
      onClick: () => handlePrint(apiData && apiData.data.file.temporaryUrl),
      hidden: apiData && apiData.data.file ? false : true,
    },
    {
      icon: <ShareIcon />,
      name: t("Share"),
      onClick: () => setOpenShare(true),
    },
  ];
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }
  function handleCopy() {
    const dataCopied = {};
    apiData.data.positions.forEach((position) => {
      delete position.id;
    });
    dataCopied.positions = apiData.data.positions;
    dataCopied.note = apiData.data.note;
    dataCopied.title = apiData.data.title;

    setStartData(dataCopied);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }

  // share
  const [openShare, setOpenShare] = useState(false);
  function handleCloseShare() {
    setOpenShare(!openShare);
  }

  // print
  function handlePrint(pdfUrl) {
    // Open a new window or iframe with the PDF URL
    const printWindow = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load
    printWindow.onload = () => {
      // Trigger the print dialog
      printWindow.print();
    };
  }

  //rensend mail
  const [openResendMail, setOpenResendMail] = useState(false);

  return (
    <Box className="flex flex-col gap-4 relative">
      {!apiData ? (
        <InvoiceSkeleton />
      ) : (
        <>
          <ExoResendMail
            recipient={apiData.data.recipient}
            type="invoices"
            id={apiData.data.id}
            open={openResendMail}
            onClose={() => setOpenResendMail(false)}
          />
          <SpeedDialMenu actions={actions} />
          <TextCard text={apiData.data.title} />
          <CardGrid>
            <PictureNameCard
              title={t("Recipient")}
              pictureUrl={
                apiData.data.recipient.logo &&
                apiData.data.recipient.logo.thumbnailUrl
              }
              name={
                apiData.data.recipient.firstName
                  ? apiData.data.recipient.firstName +
                    " " +
                    apiData.data.recipient.lastName
                  : apiData.data.recipient.name
              }
              colorPool={apiData.data.colorPool}
            />
            <InfosAndStatus
              invoice={apiData.data}
              row={row}
              onUpdate={onUpdate}
              setInvoice={onUpdate}
              handleAlertOpen={handleAlertOpen}
            />

            <FileCard
              files={[
                {
                  label: t("Invoice"),
                  icon: <DownloadIcon />,
                  url: apiData.data.file ? apiData.data.file.temporaryUrl : "",
                },
              ]}
            />
            <ImagesCard images={apiData.data.additionalFiles} />
          </CardGrid>

          <CardContainer
            sx={{
              display: "block",
              padding: "0px",
            }}
          >
            <PreviewPositions positions={apiData.data.positions} />
          </CardContainer>

          {apiData.data.note && (
            <CardContainer>
              <StyledTextContainer backgroundColor="transparent">
                <RenderHTML HTML={apiData.data.note} />
              </StyledTextContainer>
            </CardContainer>
          )}
        </>
      )}
      <NewInvoice
        open={open}
        onUpdate={handleUpdate}
        onClose={() => setOpen(false)}
        startData={startData}
        type={Boolean(startData) ? "custom" : null}
      />
      <PopUpContainer open={openShare} onClose={handleCloseShare}>
        <Share
          title={`${t("Share Invoice")} ${apiData && apiData.data.nr}`}
          url={
            apiData && apiData.data.file ? apiData.data.file.temporaryUrl : ""
          }
        />
      </PopUpContainer>
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

const InfosAndStatus = ({
  invoice,
  onUpdate,
  setInvoice,
  handleAlertOpen,
  row,
}) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(invoice.status);
  const config = useConfig();
  useEffect(() => {
    if (invoice.id && invoice.status !== status) {
      const request = { status: status };
      onUpdate({ ...row, status: status });
      setInvoice({ ...invoice, status: status });
      DBRequest({
        config,
        path: `invoices/${invoice.id}`,
        method: API_PATCH(request),
        onFeedback: handleAlertOpen,
      });
    }
  }, [status]);

  const customContent = (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={status}
        label="Status"
        onChange={(e) => setStatus(e.target.value)}
      >
        <MenuItem value={0}>{t("Created")}</MenuItem>
        <MenuItem value={1}>{t("Payed")}</MenuItem>
        <MenuItem value={2}>{t("Sent")}</MenuItem>
        <MenuItem value={3}>{t("In Arrears")}</MenuItem>
        <MenuItem value={4}>{t("Refusal to Pay")}</MenuItem>
      </Select>
    </FormControl>
  );

  const infos = InfoTypes(invoice);

  return <InfoCard infos={infos} customContent={customContent} />;
};

const InvoiceSkeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={54} span={4} />
      <SkeletonCard height={146} />
      <SkeletonCard height={146} />
      <SkeletonCard height={146} />
      <SkeletonCard height={146} />
      <SkeletonCard height={156} span={4} />
    </CardGrid>
  );
};

export default ExtendInvoice;
