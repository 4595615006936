import { useTheme, Box } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { API_POST, DBRequest, useConfig } from "../../api/api";
import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import StepRecipient from "../steps/StepRecipient";
import StepPositions from "../steps/StepPositions";
import StepCustomStartUp from "../steps/StepCustomStartUp";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import moment from "moment";
import StepUser from "../steps/StepUser";

const NewCustomOrder = ({ orders, onCreate, onClose = {}, edit = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const fields = [
    {
      label: t("Title"),
      type: "text",
      key: "title",
    },
    {
      label: t("Start Date"),
      type: "date",
      key: "startDate",
    },
    {
      label: t("Delivery Date"),
      type: "date",
      key: "deliveryDate",
    },

    {
      label: t("Note"),
      type: "description",
      key: "note",
      multiline: true,
    },
    {
      type: "hidden",
      key: "orderType",
      value: "custom",
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title is required")),
    startDate: Yup.string().required(t("Start Date is required")),
    deliveryDate: Yup.string().required(t("Delivery Date is required")),
  });

  const steps = [
    {
      label: t("Start"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={fields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={validationSchema}
        />
      ),
    },
    {
      label: t("Recipient"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Contact Person"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Custom Positions"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepPositions
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
  ];
  const config = useConfig();
  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setSuccess,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);
      DBRequest({
        config,
        path: "orders",
        method: API_POST(dataCollection),
        onResponse: handleResponse,
        onLoading: setIsLoading,
        onError: handleError,
      });
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={orders} setData={onCreate} />
    ), // component with final operations and the feedback
  };

  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    if (!config) return;
    const today = moment().format();
    const newDefaultData = {
      startDate: today,
      deliveryDate: today,
      sourceType: "custom",
    };
    setDefaultData(newDefaultData);
  }, [config]);

  return (
    <ExoStepper
      label={edit ? t("Edit Order") : t("New Order")}
      startDataCollection={defaultData}
      onClose={onClose}
      data={steps}
      finalStep={finalStep}
      previewDocument={"order"}
    />
  );
};

export default NewCustomOrder;
