import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import React, { useState } from "react";
import PopUpTextEditor from "../popup/PopUpTextEditor";
import StyledTextContainer from "../preview/StyledTextContainer";
import LabeledBox from "../special/LabeledBox";
import { useTranslation } from "react-i18next";

const Trans = (string) => {
  const { t } = useTranslation();
  const ret = t(string);
  return ret;
};

const NewDescription = ({
  onSave,
  startHTML,
  label = Trans("Description"),
  showVars,
  onPreventEnter,
}) => {
  const [openEditor, setOpenEditor] = useState(false);
  function handleClick() {
    setOpenEditor(!openEditor);
    if (onPreventEnter) onPreventEnter(!openEditor);
  }

  return (
    <>
      <LabeledBox onClick={handleClick} label={label}>
        {startHTML && (
          <StyledTextContainer fullWidth backgroundColor="transparent">
            <Box
              dangerouslySetInnerHTML={{
                __html: startHTML || "",
              }}
            ></Box>
          </StyledTextContainer>
        )}
      </LabeledBox>
      <PopUpTextEditor
        open={openEditor}
        onClose={handleClick}
        onSave={onSave}
        startHTML={startHTML || ""}
        showVars={showVars}
      />
    </>
  );
};

export default NewDescription;
