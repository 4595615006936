import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { API_POST, API_PATCH, DBRequest, useConfig } from "../../api/api";
import StepRecipient from "../steps/StepRecipient";
import StepPositions from "../steps/StepPositions";
import StepDelivery from "../steps/StepDelivery";
import StepCustomStartUp from "../steps/StepCustomStartUp";
import findDifferences from "../exo/findDifferencesInDataCollection";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ExoDialog from "../exo/ExoDialog";
import StepUser from "../steps/StepUser";

const NewOffer = ({
  offers,
  setOffers,
  onClose,
  open,
  startData = {},
  onUpdate,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const fields = [
    {
      label: t("Title"),
      type: "text",
      key: "title",
    },
    {
      label: t("Valid Until"),
      type: "date",
      key: "validUntil",
    },
    {
      label: t("Note"),
      type: "description",
      key: "note",
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    validUntil: Yup.string().required("Date is required"),
  });

  const steps = [
    {
      label: t("Recipient"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Date & Comment"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={fields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={validationSchema}
        />
      ),
    },
    {
      label: t("Positions"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepPositions
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },

    {
      label: t("Contact Person"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },

    {
      label: t("Delivery"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepDelivery
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
  ];

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);

      const diff = findDifferences(startData, dataCollection);
      DBRequest({
        config,
        path: startData && startData.id ? `offers/${startData.id}` : `offers`,
        method:
          startData && startData.id
            ? API_PATCH(diff)
            : API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={offers} setData={setOffers} />
    ), // component with final operations and the feedback
  };

  return (
    <ExoDialog open={open}>
      <ExoStepper
        label={startData && startData.id ? t("Edit Offer") : t("New Offer")}
        message={t("Create Offer")}
        startDataCollection={startData}
        onClose={onClose}
        data={steps}
        finalStep={finalStep}
        previewDocument="offer"
      />
    </ExoDialog>
  );
};
export default NewOffer;
