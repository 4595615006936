import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../../global/theme/tokens";
import { useTheme } from "@emotion/react";
import InventoryIcon from "@mui/icons-material/Inventory";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StyledTextContainer from "../../../preview/StyledTextContainer";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

const EntryNameConst = ({ name, description, tooltip, type = "" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [expanded, setExpanded] = useState(false);
  // accordion
  const handleIconClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Tooltip title={tooltip} arrow>
      {description ? (
        <Accordion
          expanded={expanded}
          sx={{
            backgroundImage: "none",
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: "6px!important",
          }}
        >
          <AccordionSummary
            height={20}
            sx={{
              margin: "0px",
              padding: "0px",
              minHeight: "26px!important",
              height: "26px!important",
            }}
            onClick={handleIconClick}
            expandIcon={<ExpandMoreIcon onClick={handleIconClick} />}
          >
            <Box
              sx={{
                height: "26px",
              }}
              className="px-0 flex flex-row items-center rounded-md gap-2"
            >
              {type === "product" && (
                <Tooltip title="Product" arrow>
                  <InventoryIcon />
                </Tooltip>
              )}
              {type === "service" && (
                <Tooltip title="Service" arrow>
                  <SupervisedUserCircleIcon />
                </Tooltip>
              )}
              {name}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              margin: "0px",
              padding: "0px",
            }}
          >
            <StyledTextContainer backgroundColor="transparent" fullWidth>
              <Box
                className="py-2 px-4 rounded-md"
                sx={{
                  padding: "10px 15px!important",
                  border: "1px solid " + colors.primary[400],
                  marginTop: "10px!important",
                }}
                dangerouslySetInnerHTML={{
                  __html: description ? description : "No Description",
                }}
              ></Box>
            </StyledTextContainer>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box
          sx={{ height: "26px" }}
          className="px-0 flex flex-row items-center rounded-md gap-2"
        >
          <InventoryIcon />
          {name}
        </Box>
      )}
    </Tooltip>
  );
};

export default EntryNameConst;
